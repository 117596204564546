import { Component, OnInit, Input } from '@angular/core';
import { FlowControlService } from '../services/flow-control.service';
import { DVMService } from '../services/dvm.service';
import { UserService } from 'src/app/services';
import { CustomerPlan } from 'src/app/shared/models';


@Component({
  selector: 'app-price-scales',
  templateUrl: './price-scales.component.html',
  styleUrls: ['./price-scales.component.scss']
})
export class PriceScalesComponent implements OnInit {

  constructor(
    public  flow: FlowControlService,
    private user: UserService,
    private dvm:  DVMService,
  ) {}

  @Input() cartType: 'simple' | 'advanced' | 'none' = 'simple'; // Determina el tipo de carrito

  public customerPlan!:    CustomerPlan;                        // El Objeto Customer Plan para montar el carrito
  public availabilty     = this.flow.availabilty;               // Disponibilidad del evento
  public includes        = Array.prototype.includes;            // Método Includes para la template y iterar en el getter de Hovered Sections
  public cart            = {};                                  // Carrito
  public isOpen: boolean = true;                                // Estado interno del selector de price scales (abierto  o cerrado)

  /**
   * Devuelve el Valor de Availabilty (cuando haya)
   */
  get availabiltyValues(): any {
    if(Object.values(this.availabilty.getValue()).length){
      return Object.values(this.availabilty.getValue())
    }else{
      return []
    }
  }

  /**
   * Recuperamos el length de los price scale para mostrar en parentesis
   */
  get availabiltyLength(): number {
    return Object.values(this.availabiltyValues).length;
  }

  /**
   * Retorna la seccion hovereada en el dvm
   */
  get hoveredSections(): string[] {
    if(this.flow.hoverSection){
      let sections = [];
      let array = Object.values(this.availabilty.getValue())
      for (let i = 0; i < array.length; i++) {
      const obj: any = array[i];
      const secciones = obj.sections;

      if(secciones.includes(this.flow.hoverSection)){
       sections.push(obj.code);
      }
    }
    return sections;
    }
    return [];
  }

  /**
   * Devuelve información acerca del price scale seleccionado
   */
  get priceScaleSelected(): any {
    if(this.cartType != 'none' && this.flow.selectedSection){
      if(typeof this.flow.selectedSection === 'object'){
        return this.flow.selectedSection;
       }else if(typeof this.flow.selectedSection === 'string'){
        let section;
        let array = Object.values(this.availabilty.getValue())
        for (let i = 0; i < array.length; i++) {
          const obj: any = array[i];
          const secciones = obj.sections;

          if(secciones.includes(this.flow.selectedSection)){
           section = obj;
          }
        }
        return section;
       }
    }else{
      return null
    }
  }

  /**
   * Retorna la llave de la price scale seleccionada
   */
  get priceScaleKey(): string{
    if(Object.keys(this.cart).length > 0){
      //@ts-ignore
      const section = Object.values(this.cart)[0].priceScaleData
      //@ts-ignore
      return this.getKeyByValue(this.availabilty.getValue(), section);
    }
  }

  /**
   * Vinculamos Customer Plan
   */
  ngOnInit(): void {
    this.flow.selectedSection = null;
    this.customerPlan = this.user.getCustomerPlanById(this.flow.currentPlan);
    this.flow.removedPriceScale.asObservable().subscribe(data => {
      this.cart[data] ? this.cart[data].num_tickets = 0 : false;
    })
  }



  public togglePriceScales():void {
      this.isOpen = !this.isOpen;
  }

  /**
   * Retorna el mejor precio para una seccion / price scale
   * @param section
   * @returns
   */
  public getBestPrice(section): number{

      let prices: any = Object.values(section.prices);
      let pricesArray:  number[] = [];

      prices.forEach(price => pricesArray.push(price.price));
      return Math.min(...pricesArray);

  }

  /**
   * Añade 1 de cantidad al carrito al Price Scale elegido
   * @param buyerTypeId
   */
  public add(priceScaleCode: any): void {
    this.cart[priceScaleCode].num_tickets += 1;
    this.updateCart()
  }

  /**
   * Resta 1 (si es posible), a la cantidad de price scale seleccionada.
   * @param buyerTypeId
   * @returns
   */
  public substract(buyerTypeId: string ): void {
    if(this.cart[buyerTypeId].num_tickets === 0){
      return
    }
    this.cart[buyerTypeId].num_tickets -= 1;
    this.updateCart()

  }

  /**
   * Hace Hover en el DVM
   * @param sectionId
   */
  public hoverPriceScale(sections: string[]): void {
    const sectionsString = [];
    for ( const section of sections) {
      sectionsString.push(section.toString());
    }
    console.log(sectionsString);
    this.dvm.viewer.hover(sectionsString);
  }

  /**
   * Resetea el estado de hover en el DVM
   */
  public leavePriceScale(): void{
    this.dvm.viewer.hover([]);
    this.flow.hoverSection = null;
  }

  /**
   * Selecciona y cambia al modo de selección de cantidad (si no es preview)
   * @param sectionId
   * @returns
   */
  public selectPriceScale(sectionId): void {

    const section = this.availabilty.getValue()[sectionId];

    if(this.priceScaleSelected && section && (this.priceScaleSelected.code === section.code)){
      this.unselectPriceScale()
      return;
    }

    this.unselectPriceScale();

    if(section.sections.length){
      this.dvm.viewer.select(section.sections);
    }

    if(this.cartType != 'none'){
      this.flow.selectedSection = section;
      this.setCart()
    }

  }

  /**
   * Si hay Price Scale Selected, quita la selección y resetea dvm
   */
  public unselectPriceScale():void{
      this.flow.selectedSection = null;
      this.flow.Basket = {};
      this.cart = {}
      this.dvm.viewer.goTo([0, 0], this.dvm.viewer.min_scaling_factor);
      this.dvm.viewer.unselectAll()
  }

  /**
   * Setea el carrito dependiendo de si es simple o avanzado
   */
  private setCart(): void {
    const CustomerCart: any = {}

    if(this.cartType != 'none' && this.priceScaleSelected){
      let prices = Object.values(this.priceScaleSelected.prices)[0];
      let data   = {num_tickets: 0, price: prices, priceScaleData: this.priceScaleSelected};
      CustomerCart[this.priceScaleSelected.code] = data;
    }

    this.cart = CustomerCart;
    this.flow.Basket = {};
    this.flow.flexCart = {};
  }

  /**
   * Retorna el carrito actualizado
   */
  private updateCart(): void {
   const filteredCart = this.formatCart({...this.cart})

   const definitiveCart = {
      customer_plan: this.customerPlan.id,
      price_scale: this.priceScaleKey,
      tickets: filteredCart
    }

    this.flow.Basket   = filteredCart;
    this.flow.flexCart = definitiveCart;
  }

  /**
   * Función Helper que devuelve la llave de un objeto a través de su valor.
   * @param object
   * @param value
   * @returns
   */
  private getKeyByValue(object, value): string {
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const currentObject = object[key];
        if (this.isObjectEqual(currentObject, value)) {
          return key;
        }
      }
    }
    return '';
  }

  private isObjectEqual(obj1, obj2): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private formatCart(cart): any{
    for(const [key,value] of Object.entries(cart)){
      //@ts-ignore
      if(value.num_tickets === 0){
        delete cart[key]
      }
    }
    return cart;
  }

}
