import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddApiUrlInterceptor, WithCredentialsInterceptor, HttpXsrfInterceptorMod } from './interceptors';
import { ModalComponent } from './modals/modal.component';
import { MessagingService } from './modals/messaging.service';
import { SalesRepComponent } from './sales-rep/sales-rep.component';
import { CongratulationsModalComponent } from './modals/congratulations-modal/congratulations-modal.component';
import { ModalsService } from './modals/modals.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { RestartModalComponent } from './modals/restart-modal/restart-modal.component';
import { ParkingSlotModalComponent } from './modals/parking-slot-modal/parking-slot-modal.component';
import { FormsModule } from '@angular/forms';
import { PanoViewModalComponent } from './modals/pano-view-modal/pano-view-modal.component';
import { PlanSeatElementModalComponent } from './modals/plan-seat-element-modal/plan-seat-element-modal.component';
import { InfoModalComponent } from './modals/info-modal/info-modal.component';
import { UpgradeModalComponent } from './modals/upgrade-modal/upgrade-modal.component';
import { CheckoutModalComponent } from './modals/checkout-modal/checkout-modal.component';
import { GeneralAdmissionModalComponent } from './modals/general-admission-modal/general-admission-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalParkingSellComponent } from './modals/modal-parking-sell/modal-parking-sell.component';
// tslint:disable-next-line:max-line-length
import { NationalsTermsAndConditionsModalComponent } from './modals/nationals-terms-and-conditions-modal/nationals-terms-and-conditions-modal.component';
import { TwinsWarmWelcomeModalComponent } from './modals/twins-warm-welcome-modal/twins-warm-welcome-modal.component';
import { SectionFormatterPipe } from './pipes/section-formatter.pipe';
import { TwinsFlexAddonsModalComponent } from './modals/twins-flex-addons-modal/twins-flex-addons-modal.component';
import { RouterModule } from '@angular/router';
import { AddDigitalWalletModalComponent } from './modals/add-digital-wallet-modal/add-digital-wallet-modal.component';
import { WarmWelcomeModalComponent } from './modals/warm-welcome-modal/warm-welcome-modal.component';



@NgModule({
  declarations: [
    ModalComponent,
    SalesRepComponent,
    CongratulationsModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    ParkingSlotModalComponent,
    PanoViewModalComponent,
    PlanSeatElementModalComponent,
    InfoModalComponent,
    UpgradeModalComponent,
    CheckoutModalComponent,
    GeneralAdmissionModalComponent,
    ModalParkingSellComponent,
    NationalsTermsAndConditionsModalComponent,
    TwinsWarmWelcomeModalComponent,
    SectionFormatterPipe,
    TwinsFlexAddonsModalComponent,
    AddDigitalWalletModalComponent,
    WarmWelcomeModalComponent,
  ],
  imports: [
    ModalModule.forRoot(),
    CommonModule,
    FormsModule,
    RouterModule],
  exports: [
    SalesRepComponent,
    SectionFormatterPipe
  ],
  entryComponents: [
    ModalComponent,
    CongratulationsModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    ParkingSlotModalComponent,
    PanoViewModalComponent,
    PlanSeatElementModalComponent,
    InfoModalComponent,
    UpgradeModalComponent,
    CheckoutModalComponent,
    GeneralAdmissionModalComponent,
    NationalsTermsAndConditionsModalComponent,
    ModalParkingSellComponent,
    TwinsWarmWelcomeModalComponent,
    TwinsFlexAddonsModalComponent,
    AddDigitalWalletModalComponent,
    WarmWelcomeModalComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorMod, multi: true},
    MessagingService,
    ModalsService
  ]
})
export class SharedModule { }
