import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from '../../../digitalvenue/services/flow-control.service';
import { ThemeSelectorService } from '../../../services';
import { Router } from '@angular/router';
import { CustomerPlan } from '../../models';

@Component({
  selector: 'app-twins-choice-modal',
  templateUrl: './twins-choice-modal.component.html',
  styleUrls: ['./twins-choice-modal.component.scss']
})
export class TwinsChoiceModalComponent implements OnInit {

  isDarkTheme: boolean;
  @Input() fromChoice: boolean;
  @Input() customerPlan: CustomerPlan;

  constructor(public bsModalRef: BsModalRef,
              public flowControl: FlowControlService,
              private router: Router,
              private themeSelector: ThemeSelectorService) {
  }

  ngOnInit() {

  }


  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  continue(): void {
    this.bsModalRef.hide();
    this.router.navigate(['/choice', this.flowControl.currentPlan]);
  }

  goReserved() {
    if (this.customerPlan.plan.tier === 3) {
      this.decline();
      // go preview full season by default
    } else {
      this.router.navigate(['/digitalvenue/preview', this.customerPlan.id, 1]).then(() => window.location.reload());
    }
  }

}
