import { Injectable, Inject } from '@angular/core';
declare var require: any;
import { CustomerPlan, Plan, Seat } from 'src/app/shared/models';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { DV_CONFIG } from '../digitalvenue.configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { UserService } from '../../services';

import * as json_neigbors from './../../../assets/json/neighbors.json';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';

@Injectable({
    providedIn: 'root',
})

export class FlowControlService {

    public neigbors;
    private loaderActive = false;
    public latestAreaAvailability;

    public isLoaderActive() {
        return this.loaderActive;
    }

    get isGASelected() {
        return this.app_config.generalAdmissionAreas.indexOf(this.current3DView) >= 0;
    }

    constructor(@Inject(DV_CONFIG) private dvConfig: DigitalvenueConfiguration,
                @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
                private userService: UserService) {
        if (json_neigbors && json_neigbors['default']) {
            this.neigbors = json_neigbors['default'];
        }
    }
    // New Flex Mode
    public flexMode: boolean = false;
    public availabilty = new BehaviorSubject([]);
    public removedPriceScale = new BehaviorSubject<string>('');
    public addonFlexPlax: Plan;
    public flexCart;

    //Section Behaviour DVM
    public hoverSection;
    public selectedSection;

    // General admission control
    public current3DView;
    public currentGeneralAdmissionArea: string;
    public isFlex: boolean;
    // Max selection control
    public maxAddons;
    public relocationSeatsCount = 0;
    // Plan control
    public currentPlan;
    public upgradePlan = null;
    public justAddonsAllowed;
    // Basket control
    public discardedSeats = {};
    public seatsToKeep = []; // Array of IDs
    public seatsToDiscard = []; // Array of IDs
    public Basket: { [key: string]: Seat } = {};
    public extraBasket = [];
    // Availability Notification Subject
    public availabilityLoaded: Subject<any> = new Subject();
    // Maps
    private viewerMaps =
                {'nam-us-00036-lakers-premium': {id: 0, name: 'Lakers', venue: 'nam-us-00036-lakers-premium'},
                'nam-us-00036-clippers-premium': {id: 1, name: 'Clippers', venue: 'nam-us-00036-clippers-premium'},
                'nam-us-00036-kings-premium': {id: 2, name: 'Kings', venue: 'nam-us-00036-kings-premium'},
                'nam-us-00036-concert-premium': {id: 3, name: 'Concert', venue: 'nam-us-00036-concert-premium'}};
    public currentMap: Subject<any> = new Subject();
    public currentVenue = 0;

    public guideOverlayClosed = true;
    public currentGeneralAdmissionAvailability;

    public showLoader(loaderActive) {
        this.loaderActive = loaderActive;
    }

    public init(planId) {
        this.seatsToDiscard = [];
        this.seatsToKeep = [];
        this.relocationSeatsCount = 0;
        this.justAddonsAllowed = false;
        this.currentPlan = planId;
        this.Basket = {};
    }

    public getAllMaps(): any {
        return this.viewerMaps;
    }

    public getCurrentMap$(): Observable<any> {
        return this.currentMap.asObservable();
    }

    public getCurrentMap(): string {
        let a = null;
        Object.values(this.viewerMaps).forEach(
            element => {
                if (element.id === this.currentVenue) {
                    a = element.venue;
                }
            }
        );
        return a;
    }

    public setCurrentMap(currentMap: any) {
        this.currentMap.next(currentMap);
    }

    public getSuitesLoungesExtraText(nodeId) {
        if (nodeId) {
            nodeId = nodeId.split('-')[0];
        }
        let extraText = '';
        let extraTextInit = '';
        // if (this.greenSuites[nodeId]) {
        //     extraTextInit = 'Private Suites';
        //     if (nodeId.indexOf('TB') !== -1) {
        //         extraTextInit = 'Premier Tables & Lounges at San Manuel Club';
        //     }
        //     extraText = `${extraTextInit} available for the 2021-22 Season.
        //                 Click <a class="mailto" href="mailto:${this.userService.getUser()['sales_rep']['email']}">here</a>
        //                 to contact your Premium Services Manager`;
        // }
        return extraText;
    }

    showMinimap() {
        // $('#minimap').removeClass('d-none');
        // $('#minimap').addClass('d-md-block');
        // $('#minimap').css('z-index', '1');
    }
    hideMinimap() {
        // $('#minimap').removeClass('d-md-block');
        // $('#minimap').addClass('d-none');
    }
    public isOpenHouse(planId) {
      return this.userService.getCustomerPlans().find(plan => plan.id === planId).customer_plan_seats.length === 0;
    }

    // quick and dirty check if upgrade to plan is not flex
    public isFlexModeCheck(client, customerPlan: CustomerPlan): boolean {
      let flexMode = false;
      if (client === 'minnesotatwins' && customerPlan.plan.tier === 4) {
        if ( !this.upgradePlan) {
          flexMode = true;
        // if we are upgrading we need to check if we are in flex
        } else {
          if (this.addonFlexPlax && this.addonFlexPlax.id === this.upgradePlan) {
            flexMode = true;
          } else {
            flexMode = customerPlan.plan_change_availability.find(p => p.id === this.upgradePlan).tier === 4;
          }
        }
      }
      console.log(flexMode);
      return flexMode;
    }


}
