import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject, throwError, Subject } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { DjangoSessionAuthenticationService } from 'src/app/services/django-session-authentication.service';
import { end } from '@popperjs/core';

@Injectable({
    providedIn: 'root'
})

export class APIConnectionService {

    // Application Basket
    public Basket: ReplaySubject<object[]> = new ReplaySubject(1);

    constructor(private http: HttpClient,
                private auth: DjangoSessionAuthenticationService) {
    }
    // General - Send Request
    private sendRequest(endpoint, body = null) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            })
        };
        const finalUrl = `${endpoint}`;
        const observable = body ? this.http.post(finalUrl, body, httpOptions) : this.http.get(finalUrl, httpOptions);
        return observable.pipe();
    }

    private sendRequestPUT(endpoint, body = null, params?: HttpParams ) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': `application/json`
            }),
            params: params
        };
        const finalUrl = `${endpoint}`;
        // const observable = body ? this.http.post(finalUrl, body, httpOptions) : this.http.get(finalUrl, httpOptions);
        const observable = this.http.put(finalUrl, body, httpOptions);
        return observable.pipe();
    }

    /*
    *   PRIVATE METHODS
    */

    // Get Plan Availability
    private _getPlanAvailability(planId, upgrade = false) {
        let endpoint;
        if (upgrade) {
            endpoint =  `/customer-plan/${planId}/upgrade/${upgrade}/availability/`;
        } else {
            endpoint =  `/customer-plan/${planId}/availability/`;
        }
        return this.sendRequest(endpoint);
    }

    // Get Plan Section Availability
    private _getPlanSectionAvailability(planId, sectionId, upgrade = false) {
        let endpoint;
        if (upgrade) {
            endpoint =  `/customer-plan/${planId}/upgrade/${upgrade}/availability/${sectionId}/`;
        } else {
            endpoint =  `/customer-plan/${planId}/availability/${sectionId}/`;
        }
        return this.sendRequest(endpoint);
    }

    private _ParkingAvailability(planId: number): any {
        const endpoint = `/plan/${planId}/parking/availability/`;
        return this.sendRequest(endpoint);
    }

    // Transaction Hold Create
    private _createHoldTransaction(planId, seats: string[], upgrade = false) {
        const endpoint =  `/transaction/`;
        let body;
        if (upgrade) {
            body = {
                action: 'hold',
                customer_plan_id: planId,
                seats,
                plan_to_upgrade: upgrade
            };
        } else {
            body = {
                action: 'hold',
                customer_plan_id: planId,
                seats
            };
        }
        return this.sendRequest(endpoint, body);
    }

    // Change seats status from transaction
    // /tmp/seat_transaction/[transaction_id]/
    // body: {'seats':['S_204-1-1, S_Lexus Club]}
    private _editTransactionSeats(transactionId: number, seats: string[]) {
        const endpoint =  `/seat_transaction/${transactionId}/`;
        const body = {
            seats
        };
        return this.sendRequest(endpoint, body);
    }

    /**
     * Checks isolated seats
     * @param planId of customer plan
     * @param seats to be checked
     */
    private _checkIsolatedSeats(planId: number, seats: string[]) {
        const endpoint = `/customer-plan/${planId}/check_isolation/`;
        const body = {
            seats
        };
        return this.sendRequest(endpoint, body);
    }

    private putParkingRequest(body:any, idTransaction: number, queryParam?: { [key: string]: string }): Observable<any> {
        
        let params: HttpParams;
        const endpoint = `/seat_transaction/${idTransaction}/`;

        if(queryParam) {
            params = new HttpParams({fromObject: queryParam});
        }

        return queryParam ? 
            this.sendRequestPUT(endpoint, body, params) :
            this.sendRequestPUT(endpoint, body);   
    }

    /**
     * exclusive request for parking on KC ROYALS SMP
     */
    private _putParkingKCRoyals(body, idTransaction) {
        const endpoint = `/seat_transaction/${idTransaction}/`;
        return this.sendRequestPUT(endpoint, body);
    }

    /*
    *   PUBLIC METHODS
    */

    public getPlanAvailability(planId, upgrade = false): Observable<any> {
        return this._getPlanAvailability(planId, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public getPlanSeats(planId): Observable<object[]> {
        return this.auth.getUserLogged$().pipe(
            map((userData) => {
                for (const plan of userData['plans']) {
                    if (plan['id'] === planId) {
                        return plan['customer_plan_seats'];
                    }
                }
                return [];
            })
        );
    }

    public getPlanSectionAvailability(planId, sectionId, upgrade = false): Observable<any> {
        return this._getPlanSectionAvailability(planId, sectionId, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public createHoldTransaction(planId, seats: string[], upgrade = false): Observable<any> {
        return this._createHoldTransaction(planId, seats, upgrade).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public editTransactionSeats(transactionId: number, seats: string[]): Observable<any> {
        return this._editTransactionSeats(transactionId, seats).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public checkIsolatedSeats(planId, seats: string[]): Observable<any> {
        return this._checkIsolatedSeats(planId, seats).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    public parkingAvailability(planId: number): Observable<any> {
        return this._ParkingAvailability(planId).pipe(
            map((data: any) => {
                // Eliminamos los price level de parking que no nos interesan si existen.
                const keys2Delete = ['PREM', 'headers'];
                for ( const key of keys2Delete) {
                     if (data.hasOwnProperty(key)) {
                        // tslint:disable-next-line:no-string-literal
                        delete data[key];
                    }
                }
                return data;
            })
        );
    }

    public putSellParkingKCRoyals(body, transactionId: number): Observable<any> {
        return this._putParkingKCRoyals(body, transactionId).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    
    public putParking(body, transactionId: number, queryParams: {[param: string]: string} | null = null): Observable<any> {
        return this.putParkingRequest(body, transactionId, queryParams).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

  private _flexAddonsAvailability(planId: number): any {
    const endpoint = `/plan/${planId}/flex-addons-availability/`;
    return this.sendRequest(endpoint);
  }

  public flexAddonsAvailability(planId): Observable<any> {
    return this._flexAddonsAvailability(planId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
  private _braintreeTokenAddCreditCard(): any {
    const endpoint = `/customer/digital_wallet/braintree_token/`;
    return this.sendRequest(endpoint);
  }

  public braintreeTokenAddCreditCard(): Observable<any> {
    return this._braintreeTokenAddCreditCard().pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  private _digitalWalletAddCreditCard(paymentNonce): any {
    const endpoint = `/customer/digital_wallet/add/`;
    const body = {
      payment_nonce: paymentNonce
    };
    return this.sendRequest(endpoint, body);
  }

  public digitalWalletAddCreditCard(paymentNonce): Observable<any> {
    return this._digitalWalletAddCreditCard(paymentNonce).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

//   public getParkingAvailability(planId: number): Observable<any> {
//     const endpoint = `/plan/${planId}/parking/availability/`;
//     return this.sendRequest(endpoint).pipe(
//       map((data: any) => {
//         return data;
//       })
//     );
//   }
}
