import { Component, Inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FlowControlService } from '../../../digitalvenue/services/flow-control.service';
import { APP_CONFIG } from "src/app/configuration/ticket-management-portal-configuration";
import { TicketManagementPortalConfiguration } from "src/app/configuration/ticket-management-portal-configuration.model";
import { ThemeSelectorService, UserService } from '../../../services';

@Component({
  selector: 'app-warm-welcome-modal',
  templateUrl: './warm-welcome-modal.component.html',
  styleUrls: ['./warm-welcome-modal.component.scss']
})
export class WarmWelcomeModalComponent implements OnInit {
  title: string;
  message: string;
  isDarkTheme: boolean;

  constructor(
              @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              public bsModalRef: BsModalRef,
              public flowControl: FlowControlService,
              private userService: UserService,
              private themeSelector: ThemeSelectorService) {
  }

  ngOnInit() {
    this.title = this.app_config.text.modals.welcomeModalTitle;
    this.message = this.app_config.text.modals.welcomeModal;
  }


  /**
   * Hides the modal
   */
  decline(): void {
    this.bsModalRef.hide();
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

}
