import { Component, OnInit } from '@angular/core';
import { FlowControlService } from '../../../digitalvenue/services/flow-control.service';
import { PricingService } from '../../../digitalvenue/services/pricing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '../../../services/configuration.service';
import { ErrorJsonService, ThemeSelectorService } from '../../../services';
import { APIConnectionService } from '../../../digitalvenue/services/api-connection.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomerPlan, Transaction } from '../../models';
import { ParkingPriceLevelModel } from '../../models/parking-price-level.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-modal-parking-sell',
  templateUrl: './modal-parking-sell.component.html',
  styleUrls: ['./modal-parking-sell.component.scss']
})
export class ModalParkingSellComponent implements OnInit {

    reason: any;
    private parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
    isUpgrade: boolean;
    originalGeneralParking = 0;
    originalReserveParking = 0;
    customerPlan: CustomerPlan;
    transaction: Transaction;
    parkingAvailability: {
        GENRL: ParkingPriceLevelModel;
        RESV: ParkingPriceLevelModel;
    };

    nGeneral: number; // Numero de parkings para general (GENRL)
    nReserve: number; // Numero de parkings para reserve (RESV)

    constructor(private flowControl: FlowControlService,
                private themeSelector: ThemeSelectorService,
                private pricing: PricingService,
                private route: ActivatedRoute,
                private router: Router,
                private modalService: BsModalService,
                public bsModalRef: BsModalRef,
                private configService: ConfigurationService,
                private errorCodeService: ErrorJsonService,
                private connectionService: APIConnectionService) {
        this.nGeneral = 0;
        this.nReserve = 0;
    }

    ngOnInit() {
        this.isUpgrade = !!(this.flowControl.upgradePlan);
        if (this.isUpgrade) {
            this.calculateSeatUpgradeCase(); // Si es upgrade le sumamos tantos parking como ya tienen.
        }
    }

    onSubmit(form: NgForm): void {}

    addParking(type: string): void {
      if (type === 'GENRL') {
          this.nGeneral += 1;
      } else if (type === 'RESV') {
          this.nReserve += 1;
      }
    }

    removeParking(type: string): void {
        if (type === 'GENRL') {
            if (!this.isUpgrade && this.nGeneral > 0) {
                this.nGeneral -= 1;
            } else if ((this.isUpgrade) && (this.nGeneral > this.originalGeneralParking)) {
                this.nGeneral -= 1;
            }
        } else if (type === 'RESV') {
            if (!this.isUpgrade && this.nReserve > 0) {
                this.nReserve -= 1;
            } else if ((this.isUpgrade) && (this.nReserve > this.originalReserveParking)) {
                this.nReserve -= 1;
            }
        }
    }

    confirm(): void {
        if (this.nReserve === 0 && this.nGeneral === 0) {
            this.onClose('confirm');
        }
        // Lo primero preparamos el body de la response
        const requestBody = { parking_seats: {} };
        if (this.nGeneral > 0) {
            const buyerTypeId = this.parkingAvailability.GENRL.buyer_types[0].buyer_type_id;
            requestBody.parking_seats[this.parkingAvailability.GENRL.price_scale_id] = {};
            requestBody.parking_seats[this.parkingAvailability.GENRL.price_scale_id][buyerTypeId] = this.nGeneral;
        }
        if (this.nReserve > 0) {
            const buyerTypeId = this.parkingAvailability.RESV.buyer_types[0].buyer_type_id;
            requestBody.parking_seats[this.parkingAvailability.RESV.price_scale_id] = {};
            requestBody.parking_seats[this.parkingAvailability.RESV.price_scale_id][buyerTypeId] = this.nReserve;
        }
        // Time to request a la api
        this.connectionService.putSellParkingKCRoyals(requestBody, this.transaction.id).subscribe(
            (response: any) => {
                this.onClose('confirm');
            }
        );
    }

    cancel(): void {
        this.onClose('cancel');
    }

    onClose(reason: 'confirm' | 'cancel' | 'restart'): void {
        this.reason = reason;
        this.bsModalRef.hide();
    }

    getTheme(): boolean {
        return this.themeSelector.isDarkTheme;
    }

    calculateSeatUpgradeCase(): void {
        for (const seat of this.customerPlan.customer_plan_seats) {
            if (seat.section === 'PARKING') {
                this.nGeneral += 1;
                this.originalGeneralParking += 1;
            }
            if (seat.section === 'PARKRES') {
                this.nReserve += 1;
                this.originalReserveParking += 1;
            }
        }
    }
}
