import { Component, Inject, OnInit } from '@angular/core';
import { FlowControlService } from 'src/app/digitalvenue/services/flow-control.service';
import { ThemeSelectorService } from 'src/app/services';
import { ParkingPriceLevelModel } from '../../models/parking-price-level.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Transaction } from '../../models';
import { APIConnectionService } from 'src/app/digitalvenue/services/api-connection.service';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';

@Component({
  selector: 'app-add-parking-generic-modal',
  templateUrl: './add-parking-generic-modal.component.html',
  styleUrls: ['./add-parking-generic-modal.component.scss']
})
export class AddParkingGenericModalComponent implements OnInit {

  constructor(
    @Inject(APP_CONFIG)
    private config:        TicketManagementPortalConfiguration,
    private flowControl:   FlowControlService,
    private themeSelector: ThemeSelectorService,
    private bsModalRef:    BsModalRef,
    private connectionService: APIConnectionService,
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  public parkingAvailability!: ParkingPriceLevelModel;

  public parkingAvailabilityValues: Map<string, ParkingPriceLevelModel> = new Map();

  public userSelections = {
    priceScale: null,
    buyerType:  null,
    quantity:   1,
  }

  public transactionId!: Transaction['id'];

  public errorMessage!:  string;

  protected reason: 'confirm' | 'cancel' | 'restart';

  public get quantitySelected(): number {
    return this.userSelections.quantity;
  }

  public get maxQuantity(): number {
    return this.config.rules.parkingMaxSlots;
  }

  public getTheme(): boolean {
    return this.themeSelector.isDarkTheme;
  }

  public confirm(): void {
   
    const body = {
      buyer_type_id : this.userSelections.buyerType.buyer_type_id,
      price_scale_id: this.userSelections.priceScale.price_scale_id,
      seats:          this.userSelections.quantity,
    },
    queryParams: {[param: string]: string} = {purchase_flow: 'parking'};

    this.connectionService.putParking(body, this.transactionId, queryParams).subscribe({
      next: ()=> {
        this.errorMessage = undefined;
        this.onClose('confirm')
      },
      error: (error) => {
        this.errorMessage = error.error.message;
        setTimeout(()=>{this.errorMessage = undefined}, 5000);
      },
    })

  }

  public onClose(reason: 'confirm' | 'cancel' | 'restart'): void {
    this.reason = reason;
    this.bsModalRef.hide();
}

  public selectPriceScale(priceScaleId: string): void {
    console.log(priceScaleId);
    this.userSelections.priceScale = this.parkingAvailability[priceScaleId];
  }

  public restart(): void {
    this.userSelections = {
      priceScale: null,
      buyerType:  null,
      quantity:   1,
    }
  }

  public selectBuyerType(buyerTypeId: string): void {
    const priceScale: ParkingPriceLevelModel['buyer_types'] = this.userSelections.priceScale.prices
    this.userSelections.buyerType = priceScale.find(buyerType => buyerType.buyer_type_id === parseInt(buyerTypeId));
    this.userSelections.quantity  = 1;
  }

  public unselectBuyerType(): void {
    this.userSelections.buyerType = null;
    this.userSelections.quantity  = 0;
  }

  private initComponent(): void {
    this.parkingAvailabilityValues = new Map(Object.entries(this.parkingAvailability));
  }

}
