import { Component, Inject, OnInit } from '@angular/core';
import { ChoiceConfig } from '../choice.configuration';
import { ChoiceItem } from '../choice-configuration.model';
import { APIConnectionService } from '../../digitalvenue/services/api-connection.service';
import { FlowControlService } from '../../digitalvenue/services/flow-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DjangoSessionAuthenticationService, UserService } from '../../services';
import { ModalsService } from '../../shared/modals/modals.service';
import { ConfigurationService } from '../../services/configuration.service';
import { CustomerPlan } from '../../shared/models';
import { DVMService } from 'src/app/digitalvenue/services/dvm.service';
import { MessagingService } from '../../shared/modals/messaging.service';

@Component({
  selector: 'app-choice-landing',
  templateUrl: './choice-landing.component.html',
  styleUrls: ['./choice-landing.component.scss']
})
export class ChoiceLandingComponent implements OnInit {
  selectedChoice: ChoiceItem;
  customerPlanId: number;
  customerPlan: CustomerPlan;

  constructor(
    @Inject(ChoiceConfig) public choiceConfig: ChoiceItem [],
    private connectionService: APIConnectionService,
    private route: ActivatedRoute,
    private authService: DjangoSessionAuthenticationService,
    private userService: UserService,
    private modalService: ModalsService,
    private messageService: MessagingService,
    private router: Router,
    private configService: ConfigurationService,
    private flowControl: FlowControlService,
    private dvm: DVMService,
  ) {
  }


  ngOnInit() {
    this.selectedChoice = this.choiceConfig[5];
    this.customerPlanId = parseInt(this.route.snapshot.paramMap.get('customerPlanId'), 10);
    this.flowControl.currentPlan = this.customerPlanId;
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    this.dvm.restartDVM();
    if (!this.customerPlan) {
      this.home();
    }
  }

  get viewer() {
    return this.dvm.viewer;
  }

  ngAfterViewChecked(): void {
    this.dvm.viewerSubject.subscribe(val => {
      val.flags.fixed_aspect_ratio = false;
    });
  }

  onSelectChoice(choice: ChoiceItem) {
    this.selectedChoice = choice;

    this.viewer.setAvailability('section', choice.sections);
  }

  home() {
    this.router.navigate(['/home']);
  }

  prePurchaseChoice() {
    if (this.selectedChoice.priceScaleId === null) {
      return;
    }
    const targetPlan = this.selectedChoice.planId;
    if (!this.customerPlan.plan_change_availability.find(p => p.id === targetPlan)) {
      this.modalService.errorModal('You cannot change your current plan for this choice, if you are an existing Choice ' +
        'Membership holder you can only change your Choice Membership for a higher priced one');
      return;
    }
    const confirmMessage = 'By clicking confirm, your new Choice Membership will be placed on hold. Do you want to continue?'
    this.messageService.info('Do you want to Continue?', confirmMessage, 'Confirm', this.purchaseChoice.bind(this), 'Cancel')

  }
  purchaseChoice() {

    const priceScaleName = this.selectedChoice.priceScaleName;
    const priceScaleId = this.selectedChoice.priceScaleId;
    const targetPlan = this.selectedChoice.planId;
    const quantity = 1;
    const basket = [`Price_scale_name:${priceScaleName}-Price_Scale_id:${priceScaleId}-target_plan:${targetPlan}-quantity:${quantity}`];
    this.flowControl.upgradePlan = targetPlan;
    this.configService.showLoader(true);
    this.connectionService.createHoldTransaction(this.flowControl.currentPlan, basket, targetPlan as unknown as boolean).subscribe(
      success => {
        this.authService.updateUserData().subscribe(
          userData => {
            const transaction = this.userService.getTransactionByPlanId(this.flowControl.currentPlan);
            this.configService.showLoader(false);
            this.router.navigate(['/checkout', this.customerPlanId]);
          },
          error => {
            this.configService.showLoader(false);
            const errorMessage = error.error;
            this.modalService.errorModal(errorMessage.message);
          }
        );
      },
      error => {
        this.configService.showLoader(false);
        const errorMessage = error.error;
        this.modalService.errorModal(errorMessage.message);
      }
    );
  }


  isSelected(choice: ChoiceItem) {
    return choice.name === this.selectedChoice.name;
  }

}
