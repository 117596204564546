import { Component, Inject, Input, OnInit, AfterViewInit } from '@angular/core';
import { DjangoSessionAuthenticationService, UserService } from 'src/app/services';
import { Router, ParamMap, Params, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ClickHandler } from '../handlers/click.handler';
import { LoadHandler } from '../handlers/load.handler';
import { DVMService } from '../services/dvm.service';
import { ColoringService } from '../services/coloring.service';
import { FlowControlService } from '../services/flow-control.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { PopoverCreateHandler } from '../handlers/popover-create.handler';
import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { NavigationHandler } from '../handlers/navigation.handler';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { PricingService } from '../services/pricing.service';

@Component({
  selector: 'app-preview-landing',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [
    ClickHandler,
    LoadHandler,
    PopoverCreateHandler,
    PopoverDestroyHandler,
    NavigationHandler
  ]
})
export class PreviewComponent implements OnInit, AfterViewInit {

  isFlexMode: boolean;
  isSelectionAllowed: boolean;
  discardedSeats: object;
  currentBasket: object[];
  planId: number;
  _upgradePlan: number;

  get viewer() {
    return this.dvmService.viewer;
  }

  @Input() set upgrade(upgrade) {
    this._upgradePlan = parseInt(upgrade, 10);
  }

  // Insert Package ID for each client's Flex Plans to handle upgrades.
  flexPlans = {
    // minnesotatwins: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
    miamimarlins: [13, 15]
  }

  constructor(private auth: DjangoSessionAuthenticationService,
              private route: ActivatedRoute,
              private dvmService: DVMService,
              private messageService: MessagingService,
              private coloring: ColoringService,
              private flowControl: FlowControlService,
              private userService: UserService,
              private modals: ModalsService,
              private clickHandler: ClickHandler,
              private loadHandler: LoadHandler,
              private configService: ConfigurationService,
              private popoverCreateHandler: PopoverCreateHandler,
              private popoverDestroyHandler: PopoverDestroyHandler,
              private navigationHandler: NavigationHandler,
              private pricing: PricingService,
              private routing: Router) {}

  ngAfterViewInit() {
    // this.coloring.applyInterfaceColors();
  }

  ngOnInit() {
    const smpClient = this.configService.client;
    this.userService.sendUrl(this.routing.url);
    // Get parameters from URL
    this.planId = parseInt(this.route.snapshot.paramMap.get('planId'), 10);
    this._upgradePlan = parseInt(this.route.snapshot.paramMap.get('upgrade'), 10);

    this.flowControl.discardedSeats = {};
    this.discardedSeats = this.flowControl.discardedSeats;
    // this.isSelectionAllowed = this.state.$current.data.isSelectionAllowed;
    this.isSelectionAllowed = false;
    this.userService.setSelectionAllowed(false);
    this.flowControl.init(this.planId);

    //DETERMINAR EL MODO (NORMAL O FLEX)
    // this.viewMode = Seteamos el modo
    // HARDCODEADO
    const customerPlan = this.userService.getCustomerPlanById(this.planId);
    // twins flex are tier 4
    if (smpClient === 'minnesotatwins' && customerPlan.plan.tier === 4) {
      this.flowControl.flexMode = true;
      this.flowControl.addonFlexPlax = null;
    }

    this.isFlexMode = this.flowControl.flexMode;

    if (this._upgradePlan) {
      this.flowControl.upgradePlan = this._upgradePlan;
      // Preview Upgrade Modal
      if (smpClient !== 'miamimarlins' || !this.flowControl.isOpenHouse(this.planId)) {
        this.modals.upgradeModal();
      }
    } else {
      this.flowControl.upgradePlan = null;
    }
    const customerPlans = this.userService.getCustomerPlans();
    const plansInit = (plans) => {
      for (const plan of plans) {
        if (plan.id === this.planId) {
          if (plan.transaction != null) {
            // PENDING:
            // switch (plan.transaction.status) {
            //   case 'PH':
            //     this.state.go('common.checkout', {planId: this.flowControl.currentPlan});
            //     break;
            //   case 'PR':
            //     this.state.go('common.summary', {planId: this.flowControl.currentPlan});
            //     break;
            //   case 'OK':
            //     this.state.go('common.summary', {planId: this.flowControl.currentPlan});
            //     break;
            //   default:
            //     break;
            // }
          }
          this.flowControl.maxAddons = plan.addons_allowed;

          // Flex plans control
          if (this.flexPlans[this.configService.client] && this.flexPlans[this.configService.client].includes(plan.plan.id)){
              this.flowControl.isFlex = true;
              if (!this._upgradePlan) {
                const message = 'No inventory is available for this plan. Please, select a plan in the selector above (Change your plan) to upgrade to a different plan.'
                this.messageService.info('Upgrade', message, 'Close');
              }
          }

          const currentBasket = [];
          plan['customer_plan_seats'].forEach(seat => {
            if (!(seat.section === 'Lexus Club' ||
              seat.section === 'Lot C' ||
              seat.section === 'Lot 1' ||
              seat.section === 'Lot E' ||
              seat.section === 'Lot W')) {
              currentBasket.push(seat);
            }
          });
          this.currentBasket = currentBasket;
        }
      }
    };
    if (!customerPlans || customerPlans.length === 0) {
      this.auth.getUserLogged$().subscribe(
        userData => {
          this.userService.setUser(userData);
          plansInit(userData['plans']);
        },
        error => {
          // this.modals.errorModal(error.message);
          console.error(error);
        }
      );
    } else {
      plansInit(customerPlans);
    }
    this.dvmService.restartDVM();
    this.pricing.init(Object.values(this.discardedSeats));
  }

}
