import { Injectable, Inject } from '@angular/core';
import { Seat } from 'src/app/shared/models';
import { ConfigurationService } from '../../services/configuration.service';
import { APP_CONFIG } from '../../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../../configuration/ticket-management-portal-configuration.model';
import { FlowControlService } from './flow-control.service';
import { UserService } from '../../services';
import { BuyerTypePirates } from './buyer-type-pirates.model';
@Injectable({
    providedIn: 'root'
})

export class PricingService {
    // Pricing storage
    public seatpricing = {};
    public areapricing = {};
    public maxPrice: {price?: number, price_type?: string} = {};

    private seatsPricingQueue = [];
    private seatsPricingQueueBuffer = [];
    // generic variable to hold the price code name of seats discarded
    // useful when we match the price based on the origin buyer type or something like this
    private priceCodeDiscarded = [];
    // used in pirates where addons depends on initial price code (buyer type id in our case)
    private initialPriceCode: string;

    constructor(private configurationService: ConfigurationService, private flowControl: FlowControlService,
                private userService: UserService,
                @Inject(APP_CONFIG) public appConfig: TicketManagementPortalConfiguration) {}

    get pricingQueueLength() {
        return this.seatsPricingQueue.length;
    }

    pricingQueueHash = {};

    init(seatsArray: Seat[]) {
        this.priceCodeDiscarded = [];
        seatsArray.sort((a, b) => a.price - b.price);
        seatsArray.map(seat => {
          this.priceCodeDiscarded.push(seat.price_code_name);
        });
        this.initialPriceCode = this.userService.getCustomerPlanById(this.flowControl.currentPlan).category;
        this.seatsPricingQueue = seatsArray;
    }

    setMaxPrice(seatsArray) {
        if (seatsArray && seatsArray.length) {
            seatsArray.sort((a, b) => a.price - b.price);
            const maxPriceSeat = seatsArray.pop();
            this.maxPrice = {price: maxPriceSeat.price, price_type: maxPriceSeat.price_type_name};
        }
    }

    getSeatPrice(seatId) {
        return this.calculatePricing(seatId, false);
    }

    setPricing(seatId) {
        return this.calculatePricing(seatId, true);
    }

    calculatePricing(seatId, setPrice) {
        // https://mmcbcn.atlassian.net/browse/IT-2685
        if (this.configurationService.client === 'minnesotatwins') {
          if (this.appConfig['delta_legends_price_types'].includes(this.seatpricing[seatId].price_type)) {
            const customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
            let hasDelta = false;
            // we get if previously has a delta
            for (const seat of customerPlan.customer_plan_seats) {
              if (this.appConfig['delta_legends_sections'].includes(seat['section'])) {
                hasDelta = true;
              }
            }
            const prices = this.seatpricing[seatId].all_prices.sort((n1, n2) => n1.price - n2.price);
            const relocationPrice = prices[0].price;
            const addonPrice = prices[1].price;
            let price = addonPrice;
            // if delta and not in list we apply relocation price
            if (hasDelta && !this.appConfig['delta_legends_new_buyer_type'].includes(parseInt(this.userService.getUser().username,10)))  {
              price = relocationPrice;
            }
            return price;
          }
        }
        if (this.configurationService.client === 'ladodgers') {
            // https://mmcbcn.atlassian.net/wiki/spaces/IS/pages/140967968/Configuration+for+season+-+LA+Dodgers+-+SMP+IT
            // LA Dodgers use original buyer type to determinate the destination buyer type for current/upgrade plan.
            // Only for Dodgers --> the User.plans.customer_plan_seat.price_code_name is used to store the origin buyer type of this customerplan
            const upgradePlan = this.flowControl.upgradePlan;
            let destinationBuyerType = null;
            let originPlanIndex = null;
            for (let [index, plan] of this.userService.user.plans.entries()){
                if (plan.id === this.flowControl.currentPlan)
                originPlanIndex = index;
            }
            try{
                const originBuyerType = this.userService.user.plans[originPlanIndex].customer_plan_seats[0].price_code_name;
                if (!upgradePlan){

                    const tier = this.userService.getCustomerPlanById(this.flowControl.currentPlan).plan.tier;
                    destinationBuyerType = this.appConfig['destinationBuyerTypeByTierAndOriginBuyerType'][originBuyerType][tier]
                }
                else{
                    for (const plan of this.userService.user.plans[originPlanIndex].plan_change_availability){
                        if (plan.id === upgradePlan)
                            destinationBuyerType = this.appConfig['destinationBuyerTypeByTierAndOriginBuyerType'][originBuyerType][plan.tier]
                    }
                }
            }
            // LAD may create empty customer plans for a customer in order to can buy multiple times. Then there is no Original Buyer Type and need to get
            // the destination buyer type from this.appConfig['BuyerTypeNewbyTier'][tier]
            catch{
                if (!upgradePlan){
                    const tier = this.userService.getCustomerPlanById(this.flowControl.currentPlan).plan.tier;
                    destinationBuyerType = this.appConfig['BuyerTypeNewbyTier'][tier]
                }
                else{
                    for (const plan of this.userService.user.plans[originPlanIndex].plan_change_availability){
                        if (plan.id === upgradePlan)
                            destinationBuyerType = this.appConfig['BuyerTypeNewbyTier'][plan.tier]
                    }
                }
            }
            for (const seat of this.seatpricing[seatId].all_prices){
                if (seat.buyer_type_id === destinationBuyerType)
                    return seat.price
            }
        }
        if (this.configurationService.client === 'clevelandguardians') {
          const customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
          let price = this.seatpricing[seatId].all_prices.find(x => x.buyer_type_type === customerPlan.category).price;
          // if price not found we'll probably have an error in the backend saying the buyer type candidate is not found
          if (!price) {
            console.log('price not found');
            price = this.seatpricing[seatId].price;
          }
          return price;
        }
        if (this.configurationService.client === 'pittsburghpirates') {
          // https://mmcbcn.atlassian.net/browse/MMC-13537?focusedCommentId=56881
          // pirates defines destination buyer type based on origin buyer type and destination plan, and distinguish between addon and
          // relocation
          const allPrices = this.seatpricing[seatId].all_prices;
          // tslint:disable-next-line: no-string-literal
          const buyerTypes = this.appConfig['buyer_types'] as BuyerTypePirates;
          // the destination plan is the current plan if we are not upgrading, if we are upgrading is the upgrade plan
          const destinationPlanId = this.flowControl.upgradePlan ? this.flowControl.upgradePlan : this.userService.getCustomerPlanById(this.flowControl.currentPlan).plan.id;
          let originBuyerType = this.initialPriceCode;
          // if we don't do this, hover on sections take out price codes from the list and messes results
          if (setPrice) {
            // 1st we try to pop one buyer type
            originBuyerType = this.priceCodeDiscarded.pop();
          } else {
            // hover price get the price available if any if not the initial
            if (originBuyerType.length !== 0) {
              originBuyerType = this.priceCodeDiscarded[this.priceCodeDiscarded.length - 1];
            }
          }
          //  the final origin buyer type is the origin if we have one or the previous saved initial price code
          const finalOriginBuyerType = originBuyerType ? originBuyerType : this.initialPriceCode;
          const destinationBuyerTypeInfo = buyerTypes[finalOriginBuyerType][destinationPlanId];
          let destinationBuyerType = null;
          // if we don't have originBuyerType is because we ran off priceCodeDiscarded hence is addon
          if (!originBuyerType) {
              destinationBuyerType = destinationBuyerTypeInfo.buyer_type_addon;
          } else {
            destinationBuyerType = destinationBuyerTypeInfo.buyer_type_normal;
          }
          if ( setPrice ) {
            console.log('setprice:' + destinationBuyerType);
          } else {
            console.log('hoverprice:' + destinationBuyerType);
          }

          // finally we search in the all prices info the price that is related to this buyer type
          return allPrices.find(x => x.buyer_type_id === destinationBuyerType).price;
        }
        const selectedPriceType = this.seatpricing[seatId].price_type;
        if (this.pricingQueueLength) {
            const queueLastPriceType = this.seatsPricingQueue[this.seatsPricingQueue.length - 1].price_type_name;
            if (queueLastPriceType === selectedPriceType) {
                if (setPrice) {
                    return this.usePrice(seatId);
                } else if (this.pricingQueueHash[seatId]) {
                    return this.pricingQueueHash[seatId];
                }
                // Pending...
                return this.maxPrice['price'];
            }
        }
        if (!this.pricingQueueLength && (selectedPriceType === this.maxPrice['price_type'])
        && this.maxPrice['price'] > this.seatpricing[seatId].price) {
            return this.maxPrice['price'];
        }
        if (selectedPriceType < this.maxPrice['price_type']) {
            if (this.maxPrice['price'] > this.seatpricing[seatId].price) {
                return this.maxPrice['price'];
            }
        }
        return this.seatpricing[seatId].price;
    }

    usePrice(seatId) {
        if (this.seatsPricingQueue.length) {
            const popped = this.seatsPricingQueue.pop();
            const price = popped.price;
            this.pricingQueueHash[seatId] = price;
            this.seatsPricingQueueBuffer.push(popped);
            return price;
        }
    }

    restorePrice(seatId) {
      console.log("restore");
      this.init(Object.values(this.flowControl.discardedSeats));
      // we recalculate the price and since this object is the same reference pass to ticket component
      // we can change price here, ugly and dangerous but working
      // tslint:disable-next-line:forin
      for (const seat in this.flowControl.Basket) {
        this.flowControl.Basket[seat].price = this.setPricing(seat);
      }
    }

}
