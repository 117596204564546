import { Component, OnInit, Input, Inject } from '@angular/core';
import { ColoringService } from '../services/coloring.service';
import { FlowControlService } from '../services/flow-control.service';
import { UserService } from 'src/app/services/user.service';
import { ThemeSelectorService } from 'src/app/services/theme-selector.service';
import { first } from 'rxjs/operators';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { DVM_CONFIG, DV_CONFIG } from '../digitalvenue.configuration';
import { CustomerPlan, Seat } from 'src/app/shared/models';
import * as globals from '../globals';
import { DVMService } from '../services/dvm.service';
import { IsolatedSeatsService } from '../services/isolated-seats.service';
import { GaService } from '../services/ga.service';
import { ErrorJsonService } from 'src/app/services';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { PricingService } from '../services/pricing.service';
import { IsolatedSeatsApiService } from '../services/isolated-seats-api.service';
declare let DVM;
declare var $: any;
import * as $ from 'jquery';
import {ConfigurationService} from "../../services/configuration.service";

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  @Input() iteration;
  @Input() currentSeatsLength;
  @Input() isSelectionAllowed: boolean;
  @Input() seat;
  @Input() discardedSeats: object;
  @Input() accordionHashSubject;
  @Input() selectAllSubject;
  @Input() isAddon: boolean;
  @Input() smpClient: string;
  @Input() isPreview: boolean;
  @Input() view3dTicket;
  @Input() isFlexMode: boolean;

  globals = globals;
  discarded = false;
  confPopover;
  contentPopover;
  formattedPrice;
  priceScale;


  get isGeneralAdmission() {
    return this.gaService.getGeneralAdmissionSections()['S_' + this.seat.section] ? this.gaService.getGeneralAdmissionSections()['S_' + this.seat.section] : false;
    // return false;
  }

  get isDiscarded() {
    return this.discarded;
  }

  get price(): string {
    return this.formattedPrice;
  }

  get isTabDisplayed() {
    if (this.accordionHash != null) {
      if (this.accordionHash[this.seat.id] != null) {
        return this.accordionHash[this.seat.id];
      }
    }
    return false;
  }

  get upgradePlan() {
    return this.flowControl.upgradePlan;
  }

  customerPlan: CustomerPlan;
  accordionHash = {};

  constructor(private userService: UserService,
              private pricing: PricingService,
              protected flowControl: FlowControlService,
              private themeSelector: ThemeSelectorService,
              private isolatedSeatsService: IsolatedSeatsService,
              private isolatedAPI: IsolatedSeatsApiService,
              private errorCode: ErrorJsonService,
              private modals: ModalsService,
              private gaService: GaService,
              private dvmService: DVMService,
              @Inject(DV_CONFIG) public app_config: DigitalvenueConfiguration,
              @Inject(DVM_CONFIG) private DVMConfig) {
  }

  mouseEnter(seat) {
  }

  mouseLeave(seat) {
  }

  toggleAccordion(event): void {
    if(this.isFlexMode){
      return;
    }
    // no display accordion and pano on parking tickets, the rest yes
      if (!this.isParking(this.seat.section)) {
          // Instead of event.toElement, use event.target because it works on Firefox and IE
          if (event.target.nodeName !== 'BUTTON' && !globals.isMobile() && this.view3dTicket) {
              // if (event.toElement.nodeName !== 'BUTTON') {
              let setTrue = true;
              if (this.accordionHash[this.seat.id] === true) {
                  setTrue = false;
              }
              // tslint:disable-next-line: forin
              for (const key in this.accordionHash) {
                  this.accordionHash[key] = false;
              }
              if (setTrue) {
                  this.accordionHash[this.seat.id] = true;
                  this.load3DView();
              }
              this.accordionHashSubject.next(this.accordionHash);
          }
      }
  }

  public toggleDiscarded(seat) {
    this.mouseLeave(seat);
    this.discarded = !this.discarded;

    if (this.discarded) {
      this.discardedSeats[seat.id] = seat;
    } else {
      if (this.discardedSeats[seat.id]) {
        delete this.discardedSeats[seat.id];
      }
    }
    this.isolatedAPI.setDiscardedSeats(this.discardedSeats);
  }

  private load3DView() {
    let id;
    if (!this.isGeneralAdmission) {
      id = this.seat.id;
    } else {
      id = 'S_' + this.seat.section;
    }
    const objView = { venue_id: this.dvmService.viewer.getVenueId(), view_id: id };
    this.view3dTicket.loadView3d(objView)
      .then((resolve) => {
        this.view3dTicket.flags.fixed_aspect_ratio = false;
        this.view3dTicket.open();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  isRelocationAvailable() {
    if (this.isPreview) {
      return !this.isPreview;
    }
    const appointmentTimestamp = new Date(this.customerPlan.appointment_date_from).getTime();
    const now = new Date().getTime();
    return (now >= appointmentTimestamp) ? true : false;
  }

  locateSeat(seat, success = null) {
    if ('S_' + seat.section !== this.dvmService.viewer.getMapId()) {
      const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
      loadOptions.map_id = seat.id.split('-')[0];
      loadOptions.venue_id = this.dvmService.viewer.getVenueId();
      this.dvmService.loadMap(loadOptions)
        .then((resolve) => {
          this.dvmService.viewer.select(seat.id);
          if (success) {
            success();
          }
        });
    } else {
      if (success) {
        success();
      }
    }
  }

  /**
   * Elimina de los carritos el price Scale seleccioando
   */
  removePriceScale(): void{
    this.flowControl.removedPriceScale.next(this.priceScale.code)
    delete this.flowControl.Basket[this.priceScale.code]
    delete this.flowControl.flexCart.tickets[this.priceScale.code];
  }

  open3dView(view, areaView = false) {

    let id;

    if (!this.isGeneralAdmission) {
      id = this.seat.id;
    } else {
      id = 'S_' + this.seat.section;
    }

    const objView = { venue_id: this.dvmService.viewer.getVenueId(), view_id: id };
    this.dvmService.viewer3d.loadView3d(objView)
      .then((resolve) => {
        this.dvmService.viewer3d.flags.fixed_aspect_ratio = false;
        this.dvmService.viewer3d.open();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  removeTicket(seat) {
    this.mouseLeave(seat);
    if (!this.isGeneralAdmission) {
      new Promise((success, reject) => {
        this.locateSeat(seat, success);
      }).then(() => {
        if ('S_' + seat.section !== this.dvmService.viewer.getMapId()) {
          this.flowControl.availabilityLoaded.pipe(first()).subscribe(
            x => {
              // this.removeSeat(seat);
              this.isolatedRemoveCheck(seat);
            });
        } else {
          // this.removeSeat(seat);
          this.isolatedRemoveCheck(seat);
        }
      });
    } else {
      if (this.flowControl.Basket[seat['id']] != null) {
        if (seat.row === 'GA') {
            this.gaService.gaHashDiscard = [seat];
        }
        delete this.flowControl.Basket[seat['id']];
        this.dvmService.viewer.unselect(seat['id']);
    }
      // this.state.seatmap.showLoader = false;
    }
  }

  private removeSeat(seat) {

      // ISOLATED SEATS ALROGITHM
      this.dvmService.viewer.unselect(seat.id);
      if (this.flowControl.Basket[seat.id] != null) {
        if (seat.row === 'GA') {
          this.gaService.gaHashDiscard = [seat];
        }
        delete this.flowControl.Basket[seat.id];
        this.dvmService.viewer.unselect(seat.id);
        this.pricing.restorePrice(seat.id);
      }
  }

  private isolatedRemoveCheck(seat) {
    let isSelected = false;
    const currentSeat = this.dvmService.viewer.getNodeById(seat.id);
    if (currentSeat) {
      isSelected = currentSeat.state === 'selected';
    }
    if (isSelected) {
      const split = seat.id.split('_')[1].split('-');
      const section = split[0];
      // tslint:disable-next-line: variable-name
      const seat_row = split[1];
      // tslint:disable-next-line: variable-name
      const seat_seat = split[2];
      const price = this.pricing.seatpricing[seat.id].price;
      const seatObject: Seat = {
        id: seat.id, section, seat_row, seat: seat_seat, price
      };
      const action = 'remove';
      if (this.isolatedSeatsService.singleSeatsBuffer.length > 0) {
        this.isolatedSeatsService.singleSeatHandling(seatObject, seat.id, action);
      } else {
        // ISOLATED SEATS ALROGITHM
        this.dvmService.viewer.unselect(seat.id);
        const selectedElements = this.dvmService.viewer.getNodesByState('seat', 'selected');
        const isolatedSeats = this.isolatedSeatsService.checkIsolatedSeats(selectedElements,
          seatObject, action);
        if (isolatedSeats.length === 0) {
          if (this.flowControl.Basket[seat.id] != null) {
            if (seat.row === 'GA') {
              this.gaService.gaHashDiscard = [seat];
            }
            delete this.flowControl.Basket[seat.id];
            this.dvmService.viewer.unselect(seat.id);
            this.pricing.restorePrice(seat.id);
          }
        } else {
          this.errorCode.getErrorByCode(1006).subscribe(
            errorMessage => {
              this.modals.errorModal(errorMessage);
            }
          );
        }
      }
    } else {
      // this.state.seatmap.showLoader = false;
    }
  }

  ngOnInit() {
    this.customerPlan = this.userService.getCustomerPlanById(this.flowControl.currentPlan);
    this.accordionHashSubject.subscribe(
      accordionHash => {
        this.accordionHash = accordionHash;
      },
      error => {
        console.error(error);
      }
    );
    this.selectAllSubject.subscribe(
      value => {
        if (this.isDiscarded !== value) {
          this.toggleDiscarded(this.seat);
        }
      },
      error => {
        console.error(error);
      }
    );
    // this.view3dTicket = this.tk3d.action('loadModule', { module: 'view3d', container: 'ticket-accordion-3dview' });

    if (!this.isSelectionAllowed && this.upgradePlan) {
      this.toggleDiscarded(this.seat);
    }
    this.processPrice();

    if(Object.keys(this.seat).includes('priceScaleData')){

      const data: any   = this.seat.priceScaleData;
      const prices: any = Object.values(data.prices);

      const newSeatObject = {
        price: prices.price,
        price_code_name: prices.code,
        seat: "GA",
        seat_row: "GA",
        section: data.name
      }
      this.seat = newSeatObject;
      this.priceScale = data;
    }
  }

  processPrice() {
    if (this.seat.price.price) {
      // const priceArray = [];
      // this.seat.price.forEach(price => {
      //   priceArray.push(parseFloat(price));
      // });
      // if (priceArray.length) {
      //   if (this.isAddon) {
      //     this.formattedPrice = Math.max(...priceArray).toLocaleString(undefined,
      // { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   } else {
      //     this.formattedPrice = Math.min(...priceArray).toLocaleString(undefined,
      // { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      //   }
      // } else {
      //   this.formattedPrice = null;
      // }
      this.formattedPrice = this.seat.price.price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    } else {
      this.formattedPrice = this.seat.price.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  isParking(section: string): boolean {
    let isparking = false;
    const parkingSectionList = ['PARKING', 'PARKRES', 'PREMIUM', 'STMRES', 'TRIPLECRWN'];
    for (const psection of parkingSectionList) {
        if (section === psection) {
            isparking = true;
        }
    }
    return isparking;
  }

}
