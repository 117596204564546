import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ChoiceLandingComponent } from './choice-landing/choice-landing.component';
import { ChoiceRoutingModule } from './choice.routing.module';
import { ChoiceConfig, ChoiceConfiguration } from './choice.configuration';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChoiceRoutingModule
  ],
  providers: [
    { provide: ChoiceConfig, useValue: ChoiceConfiguration },
  ],
  declarations: [ChoiceLandingComponent],
})
export class ChoiceModule {
}
