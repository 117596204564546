import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { ChoiceLandingComponent } from './choice-landing/choice-landing.component';

const routes: Routes = [

  {
    path: 'choice', canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: ':customerPlanId', component: ChoiceLandingComponent },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class ChoiceRoutingModule {
}
