import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { SummaryComponent } from './checkout/summary/summary.component';
import { FormsModule } from '@angular/forms';
import { APP_CONFIG, ApplicationConfiguration } from './configuration/ticket-management-portal-configuration';
import { FooterComponent } from './footer/footer.component';
import { DjangoSessionAuthenticationService } from './services';
import { LoginModule } from './login/login.module';
import { CheckoutModule } from './checkout/checkout.module';
import { DigitalvenueModule } from './digitalvenue/digitalvenue.module';
import { LandingModule } from './landing/landing.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonComponent } from './common/common.component';
import { AdminLoginBannerComponent } from './admin-login-banner/admin-login-banner.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { ConfigurationService } from './services/configuration.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { developInterceptorProvider } from './shared/interceptors/develop.interceptor';
import { ChoiceModule } from './choice-flow/choice.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SummaryComponent,
    FooterComponent,
    CommonComponent,
    AdminLoginBannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    LoginModule,
    CheckoutModule,
    DigitalvenueModule,
    LandingModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    ChoiceModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    DjangoSessionAuthenticationService,
    {provide: LOCALE_ID, useValue: 'en-US'},
    ConfigurationService,
    {
      provide: APP_INITIALIZER, useFactory: (configService: ConfigurationService) =>
        () => configService.init(), deps: [ConfigurationService], multi: true
    },
    // developInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
