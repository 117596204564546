import { Component, OnInit, HostListener, OnDestroy, Inject } from '@angular/core';
import { SeatTransaction, Seat, CustomerPlan } from 'src/app/shared/models';
import { ThemeSelectorService, UserService, DjangoSessionAuthenticationService, ErrorJsonService } from 'src/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagingService } from 'src/app/shared/modals/messaging.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { ExtraService } from 'src/app/digitalvenue/services/extra.service';
import { Observable, Subject } from 'rxjs';
import { TDCTransaction } from 'src/app/shared/models/tdc_transaction.model';
import { APP_CONFIG } from 'src/app/configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from 'src/app/configuration/ticket-management-portal-configuration.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FlowControlService } from '../../digitalvenue/services/flow-control.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";

declare var braintree: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  planId: number;
  customerPlan: CustomerPlan;
  keptSeats = [];
  selectedSeats = [];
  discardedSeats = [];
  isDarkTheme: boolean;
  innerWidth = window.innerWidth;
  smpClient;

  paymentOptions = {
    option: 'full', payment_plan: null, credit_to_apply: 0, digital_wallet: null, payment_nonce: null, card_wallet: 'card',
    no_installments: false, consume_credits: false, all_with_credit: false, payment_plan_initial_payment: false, reservation: false,
    total_balance: 0 // only for user service check and not having to pass the transaction
  };

  seatsToDiscard = [];
  discardHash = {};

  timerObservable = new Subject<string>();
  timer;

  transactionTimerLimit = this.app_config.rules.transactionTimerLimit; // In Minutes
  isTimerOver = false;
  interval;

  upgradedPlan = null;

  tdcTransaction: TDCTransaction;

  allowPaymentMethods = true;

  isFlexMode: boolean;

  digitalWalletText: string;

  get isDiscardSeatsAvailable() {
    return (this.selectedSeats.length - this.discardedSeats.length - this.seatsToDiscard.length) > 0;
  }

  /**
   * Event that detects the window size
   * @param event window resize
   */
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // gets the window width on reize
    this.innerWidth = window.innerWidth;
  }

  /**
   * Gets the timer
   */
  get getTimer() {
    return this.timer;
  }

  get quantity():string {
    if(this.customerPlan && this.isFlexMode && this.customerPlan.transaction.seat_transactions[0].action === 'add' ){
      return this.customerPlan.transaction.seat_transactions[0].seat.split('-')[2]
    }else if(this.customerPlan && this.isFlexMode && this.customerPlan.transaction.related && this.customerPlan.transaction.related.seat_transactions[0].action === 'add' ){
      return this.customerPlan.transaction.related.seat_transactions[0].seat.split('-')[2]
    } else if(this.customerPlan.transaction.seat_transactions[0].action === 'remove' ||
             this.customerPlan.transaction.seat_transactions[0].action === 'keep' ){
      return '1'
    }else{
      return '1'
    }
  }

  constructor(@Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
              private auth: DjangoSessionAuthenticationService,
              public configService: ConfigurationService,
              public extrasService: ExtraService,
              private messageService: MessagingService,
              private userService: UserService,
              public flowControlService: FlowControlService,
              private themeSelector: ThemeSelectorService,
              private router: Router,
              private routeParam: ActivatedRoute,
              private errorCodeService: ErrorJsonService,
              private modalService: ModalsService) {
      this.userService.updateUser();
  }

  setSeatToDiscard(seat) {
    this.seatsToDiscard.push(seat.id);
    this.discardHash[seat.id] = true;
  }

  removeSeatFromDiscard(seat) {
    this.seatsToDiscard.forEach(arraySeat => {
      if (arraySeat === seat.id) {
        this.seatsToDiscard.splice(this.seatsToDiscard.indexOf(arraySeat));
      }
    });
    this.discardHash[seat.id] = false;
  }

  ngOnInit() {

    this.smpClient = this.configService.client;
    if (this.app_config.rules.defaultPaymentMethod) {
        this.paymentOptions.option = this.app_config.rules.defaultPaymentMethod;
    }

    // set the default digital wallet message if there is no on text.josn
    this.digitalWalletText = this.app_config.text.checkout.selectDigitalWalletText
    ? this.app_config.text.checkout.selectDigitalWalletText
    : "Please select the digital wallet you would like to use:";

    console.log(this.flowControlService.justAddonsAllowed);
    this.allowPaymentMethods = this.app_config.rules.allowPaymentMethods;
    // gets the customer plan by id from the service
    this.planId = parseInt(this.routeParam.snapshot.paramMap.get('planId'), 10);
    // gets the customer plan by id from the service
    this.customerPlan = this.userService.getCustomerPlanById(this.planId);
    console.log(this.customerPlan);
    if (this.customerPlan.transaction != null) {
      if (this.customerPlan.transaction.status === 'PR') {
        // if transaction status it's 'PR' means that is pending reserved and has to be redirected to the summary
        this.router.navigate(['/summary', this.planId]);
      } else {
        // if transaction it's diferrent than 'PR' then filter the seats
        this.filterPlanSeats(this.customerPlan);
        this.tdcTransaction = this.customerPlan.transaction.tdc_transaction;
        if (this.customerPlan.transaction.related) {
          this.upgradedPlan = this.customerPlan.transaction.related.plan;
        }
        // if we are upgrading, this.customerPlan.transaction is slave and tdc_transaction is linked to master
        // we need the tdcTransaction from the transaction from  plan towards we are upgrading
        if (this.tdcTransaction === null) {
          const toUpgradeCustomerPlan = this.userService.getCustomerPlanById(this.customerPlan.transaction.related.customer_plan);
          this.tdcTransaction = toUpgradeCustomerPlan.transaction.tdc_transaction;
        }
        this.paymentOptions.total_balance = this.tdcTransaction.balance;
        // if (this.smpClient === 'washingtonnationals') {
        //     this.paymentOptions.total_balance = this.calculateTotalBalanceNationals();
        // }
        this.timerObservable.subscribe(
          timer => {
            this.timer = timer;
          }
        );
        this.setTimer();
        this.setTimerInterval();
      }
    } else {
      // if transaction it's null redirects to the landing page
      this.router.navigate(['/home']);
    }
    if (!this.allowPaymentMethods) {
      this.paymentOptions.reservation = true;

      // TESTING
      // this.tdcTransaction.payment_plans.forEach(element => {
      //   if (element.id == 2341) {
      //     this.paymentOptions.payment_plan = element;
      //   }
      // });
      // if (!this.paymentOptions.payment_plan) {
      //   this.paymentOptions.payment_plan = this.tdcTransaction.payment_plans[0];
      // }
      // this.paymentOptions.option = 'plan';
      // this.paymentOptions.no_installments = true;
    }

    //DETERMINAR EL MODO (NORMAL O FLEX)
    // this.viewMode = Seteamos el modo
    // HARDCODEADO
    const customerPlan = this.userService.getCustomerPlanById(this.planId);
    // twins flex are tier 4
    if (this.flowControlService.isFlexModeCheck(this.smpClient, this.customerPlan)) {
      this.flowControlService.flexMode = true;
      this.flowControlService.addonFlexPlax = null;
    }
    this.isFlexMode = this.flowControlService.flexMode;

    // Nationals ha pedido que por defecto se muestre el paymentplan desplegado. Para ello si hay un payment plan [0] lo marcamos como seleccionado.
    // https://mmcbcn.atlassian.net/browse/TICKETING-911
    if (this.smpClient === 'washingtonnationals'){
      if (this.tdcTransaction.payment_plans.length > 0) {
        this.paymentOptions.payment_plan = this.tdcTransaction.payment_plans[0];
        this.onSelectPaymentPlan(this.paymentOptions.payment_plan);
      }
    }

  }

    maxCreditValidator() {
      const c = Number(this.paymentOptions.credit_to_apply);
      // if ( this.paymentOptions.credit_to_apply == null || isNaN(c)) {
      //     this.paymentOptions.credit_to_apply = 0;
      // }
      if ( this.paymentOptions.credit_to_apply == null || isNaN(c) || c < 0 ) {
            this.paymentOptions.credit_to_apply = 0;
      } else if (c > this.tdcTransaction.credit_available) {
          this.paymentOptions.credit_to_apply = this.tdcTransaction.credit_available;
      } else if (c > this.paymentOptions.total_balance) {
          this.paymentOptions.credit_to_apply = this.paymentOptions.total_balance;
      } else {
          this.paymentOptions.credit_to_apply = c;
      }

      // version clamp
      // this.paymentOptions.credit_to_apply = this.clamp(c, 0, this.tdcTransaction.credit_available);
    }

    clamp(input: number, min: number, max: number): number {
        return Math.min(Math.max(input, min), max);
    }

  ngAfterViewInit() {
    setTimeout(() => {
      const button = document.querySelector('#braintree-button');

      braintree.dropin.create({
        authorization: this.tdcTransaction.braintree_token,
        selector: '#dropin-container'
        // tslint:disable-next-line:only-arrow-functions
      }, (err, instance) => {
        console.log(err);
        // tslint:disable-next-line:only-arrow-functions
        button.addEventListener('click', () => {
          // tslint:disable-next-line:only-arrow-functions
          instance.requestPaymentMethod((err, payload) => {
            window['payment_nonce'] = payload; // todo checkout not do with global variable
            this.paymentOptions.payment_nonce = payload;
          });
        });
      });
    }, 300);
  }

  /**
   * Parses the timestamp into days, hours, minutes and seconds
   * @param timeStamp to parse the date into string
   * @returns the formated date
   */
  time2string(timeStamp) {
    // Days
    const days = Math.floor(timeStamp / 86400);
    timeStamp -= days * 86400;
    // Hours
    const hoursNum = Math.floor(timeStamp / 3600) % 24;
    const hours = (hoursNum < 10 ? '0' : '') + hoursNum;
    timeStamp -= hoursNum * 3600;
    // Minutes
    const minutesNum = Math.floor(timeStamp / 60) % 60;
    const minutes = (minutesNum < 10 ? '0' : '') + minutesNum;
    timeStamp -= minutesNum * 60;
    // Seconds
    const secondsNum = Math.floor(timeStamp % 60);
    const seconds = (secondsNum < 10 ? '0' : '') + secondsNum;
    return minutes + ':' + seconds;
  }

  /**
   * Sets the timer and updates the observable
   */
  setTimer(): void {
    // Gets the appointment date
    const date = this.customerPlan.transaction.created;
    // Gets the timestamp of appointment date
    const appointmentTimestamp = new Date(date).getTime();
    // Gets the current date
    const now = new Date().getTime();
    // Gets the difference between current date and appointment date
    let diff = ((this.transactionTimerLimit * 60000) - (now - appointmentTimestamp)) / 1000;
    if (diff < 0) {
      diff = 0;
      // Control so it pops one time
      if (!this.isTimerOver) {
        this.transactionExpiredModal();
        this.isTimerOver = true;
      }
    }
    // Parses the difference result
    const timeString = this.time2string(diff);
    // Updates the subject with the new value
    this.timerObservable.next(timeString);
  }

  transactionExpiredModal() {
    this.messageService.info(
      'Oops!',
      `Your transaction has expired. Your new seats have been released,
      but your old seats are still reserved in your account.
      Please, click the button below to restart.`,
      'Restart',
      () => {
        // subscribe and update the user data
        this.cancelTransactionObservable().subscribe(() => {
            this.configService.showLoader(true);
            this.flowControlService.selectedSection = null;

            this.auth.updateUserData().subscribe(
                userData => {
                    // redirects to the landing page
                    this.configService.showLoader(false);
                    this.router.navigate(['/home']);
                },
                error => {
                    this.configService.showLoader(false);
                    console.error(error);
                    this.router.navigate(['/home']);
                }
            );
        }, (err => {
            this.auth.updateUserData().subscribe(
                userData => {
                    // redirects to the landing page
                    this.configService.showLoader(false);
                    this.router.navigate(['/home']);
                },
                error => {
                    console.error(error);
                    this.configService.showLoader(false);
                    this.router.navigate(['/home']);
                }
            );
        }));
      }
    );
  }

  /**
   * Sets the timer with an interval of 1 seg (to do a chronometer)
   */
  setTimerInterval(): void {
    this.interval = setInterval(() => {
      this.setTimer();
    }, 1000);
  }

  formatPrice(price: number) {
    if (price) {
      return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
    return 0;
  }

  absolute(num: number) {
    return Math.abs(num);
  }

  /**
   * Loops the array selectedSeats and sums the price of all seats
   * @returns count, the selected total price
   */
  get totalPriceSelected(): number {
    let count = 0;
    for (const seat of this.selectedSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    for (const seat of this.keptSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  /**
   * Loops the array selectedSeats and sums the price of all new seats dont count kept
   * @returns count, the selected total price
   */
  get totalPriceAddSeatsSelected(): number {
    let count = 0;
    for (const seat of this.selectedSeats) {
        if (!this.discardHash[seat.id]) {
          count += seat.price;
        }
    }
    return count;
  }

  /**
   * Loops the array discardedSeats and sums the price of all seats
   * @returns count, the discarded total price
   */
  get totalPriceDiscarded(): number {
    let count = 0;
    for (const seat of this.discardedSeats) {
      count += seat.price;
    }
    for (const seat of this.keptSeats) {
      if (!this.discardHash[seat.id]) {
        count += seat.price;
      }
    }
    // return Number.parseFloat(count + '').toFixed(2);
    return count;
  }

  // Only for NATIONALS
  get totalPriceOnlyDiscarded(): number{
      let count = 0;
      for (const seat of this.discardedSeats) {
          count += seat.price;
      }
      return count;
  }

  /**
   * Substract the total price of discarded and selected seats,
   * @returns object with the absolute price and a boolean
   */
  get totalPrice(): { 'isRefund': boolean, 'price': number } {
    // const priceSelected = this.totalPriceSelected;
    // const priceDiscarded = this.totalPriceDiscarded;
    // const price = priceSelected - priceDiscarded;

    const price = this.tdcTransaction.balance;

    let isRefund;
    // checks if it's to pay or refund
    (price < 0) ? isRefund = true : isRefund = false;
    // isRefund = false;
    return { isRefund, price };
  }

  /**
   * Displays the first confirmation modal
   */
  openCheckoutModal(): void {
    // if we have a payment plan and digital wallet selected, avoid error you need to specify a valid credit cart for payment plans
    // with initial payment.
    if (this.paymentOptions.option === 'plan' && !window['payment_nonce'] && this.paymentOptions.digital_wallet) {
      this.paymentOptions.card_wallet = 'wallet';
    }
    // check if required fields are not filled
    // if some kind of payment and credit is not all we need either credit card or digital wallet
    if (this.paymentOptions.reservation === false) { // si es true no se necesita nada mas
      // if we have selected option plan we need to select a payment plan
      if (this.paymentOptions.option === 'plan' && !this.paymentOptions.payment_plan) {
        this.messageService.error('Error', 'You need to select a payment plan');
        return;
      }
      //  if we have payment plan with installments we need to specify a digital wallet item
      if (this.paymentOptions.option === 'plan' && !this.paymentOptions.no_installments && !this.paymentOptions.digital_wallet) {
        this.messageService.error('Error', 'You need to select a digital wallet item');
        return;
      }
      if (this.paymentOptions.option === 'full' || this.paymentOptions.payment_plan_initial_payment) {
        if (this.paymentOptions.credit_to_apply < this.paymentOptions.total_balance) {
          let message = '';
          if (this.paymentOptions.card_wallet === 'card' && !window['payment_nonce']) {
            message = 'You need to specify a valid credit card';
          } else if (this.paymentOptions.card_wallet === 'wallet' && !this.paymentOptions.digital_wallet) {
            message = 'You need to select a digital wallet item';
          }
          if (message) {
            this.messageService.error('Error', message);
            return;
          }
        }
      }
    }

    if (this.getTimer === '00:00') {
      this.transactionExpiredModal();
    } else {

      console.log('-- CHECKOUT', this.paymentOptions);

      const title = 'Continue';
      let message = `By clicking confirm, your new seats will be reserved, and you will be contacted by a
      Sales Representative within 1 Business Day to review terms and prepare the necessary paperwork and payment.
      Do you want to confirm your seat selection?`;
      if (this.app_config.text.modals.checkoutModal && this.app_config.text.modals.checkoutModal !== '') {
        message = this.app_config.text.modals.checkoutModal;
      }
      const acceptBtnName = 'Confirm';
      const closeBtnName = 'Close';
      this.messageService.info(title, message, acceptBtnName, () => {
        this.openCheckout2Confirmation();
      }, closeBtnName);
    }
  }

  openCheckout2Confirmation(): void {
    const title = 'Continue';
    let message = '';
    if (this.app_config.text.modals.checkout2ConfirmationModal && this.app_config.text.modals.checkout2ConfirmationModal !== '') {
      message = this.app_config.text.modals.checkout2ConfirmationModal;
    }
    else message = 'Are you sure you want to continue?';
    const acceptBtnName = 'Confirm';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => {
      this.createReservedTransaction(this.customerPlan);
    }, closeBtnName);
  }

  /**
   * Creates the transaction in status reserved ( PR ) and redirects to the summary page,
   * if error displays an error.
   * @param customerPlan to get the selected seats
   */
  createReservedTransaction(customerPlan: CustomerPlan): void {

    // transaction object, needed the action, plan id and the array of seats
    // const transaction = {'action': 'reserve', 'plan_id': customerPlan.id, 'seats': seats};
    // console.log(transaction);
    // subscribe to the function and if it's successful then will subscribe to another observable
    // to update the user and then redirects to the summary page
    let transaction = customerPlan.transaction;
    if (!transaction.master) {
      transaction = transaction.related;
    }

    // Fill Array helper function, needed to create the extras array
    const fillArray = (value, len) => {
      if (len === 0) { return []; }
      let a = [value];
      while (a.length * 2 <= len) { a = a.concat(a); }
      if (a.length < len) { a = a.concat(a.slice(0, len - a.length)); }
      return a;
    };

    // Extras action control, calls editTransactionSeats with the correct parameters for each case
    const editExtra = (extraType: 'parkingC' | 'parkingW' | 'lexus', count: number, success = () => { }) => {
      let areaName;
      let hashName;

      if (count > 0) {
        // Add new extras
        const seats = fillArray(areaName, count);
        this.userService.editTransactionSeats(transaction.id, seats, 'add').subscribe(response => success(),
        error => {
          console.error(error);
          this.modalService.errorModal(error.error.message);
        });
      } else if (count < 0) {
        // Remove extras
        const seats = Object.keys(this.extrasService.extraHash[hashName]).splice(0, Math.abs(count));
        this.userService.editTransactionSeats(transaction.id, seats, 'remove').subscribe(response => success(),
        error => {
          console.error(error);
          this.modalService.errorModal(error.error.message);
        });
      } else {
        success();
      }
    };
    // Calls reserveTransaction to change the status of a transaction to 'Reserved' or 'Confirmed'
    const reserveTransaction = () => {
      this.configService.showLoader(true);
      this.userService.reserveTransaction(transaction.id, this.paymentOptions).subscribe(
        data => {
          this.auth.updateUserData().subscribe(
            userData => {
              this.configService.showLoader(false);
              if ((this.paymentOptions.option == 'plan' || !this.allowPaymentMethods) &&
              this.app_config.rules.redirectUrl && this.app_config.rules.redirectUrl != '') {
                // window.open(this.app_config.rules.redirectUrl, "_blank");
              }
              // if we are upgrading we have to redirect to upgraded customer plan for proper labeling on summary
              let customerPlanTarget = this.planId;
              if (this.upgradedPlan) {
                customerPlanTarget = this.customerPlan.transaction.related.customer_plan;
              }
              this.router.navigate(['/summary', customerPlanTarget]);
            },
            error => {
              this.configService.showLoader(false);
              this.modalService.errorModal(error.error.message);
            }
          );
        },
        // Displays error modal
        error => {
          this.configService.showLoader(false);
          const errorMessage = error.error;
          this.modalService.errorModal(errorMessage.message);
          // this.errorCodeService.getErrorByCode(1003).subscribe(
          //   errorText => {
          //     this.modalService.errorModal(errorText);
          //   }
          // );
        });
    };

    // Parking and Lexus selections are checked and processed
    // const parkingPromiseC = new Promise((success) => { editExtra('parkingC', newParkingC - currentParkingC, success); });
    // const parkingPromiseW = new Promise((success) => { editExtra('parkingW', newParkingW - currentParkingW, success); });
    // const lexusPromise = new Promise((success) => { editExtra('lexus', newLexus - currentLexus, success); });

    // When extras are checked and processed, deleted seats are processed and the transaction is reserved
    // Promise.all([parkingPromiseC, parkingPromiseW, lexusPromise]).then(() => {
      if (this.seatsToDiscard.length) {
        this.userService.editTransactionSeats(transaction.id, this.seatsToDiscard, 'remove').subscribe(
          response => {
            reserveTransaction();
          },
          error => {
            const errorMessage = error.error;
            if (errorMessage.code === 'TCRRS03') {
              for (let i = 0; i < errorMessage.seats.length; i++) {
                errorMessage.seats[i] = errorMessage.seats[i].split('S_')[1];
              }
              this.modalService.errorModal(`The selected seats are leaving isolated seats: ${errorMessage.seats}`);
            } else {
              console.error(error);
              this.modalService.errorModal(error.error.message);
              // If error, shows a modal with the error code
              // this.errorCodeService.getErrorByCode(1005).subscribe(
              //   errorText => {
              //     // displays an error modal
              //     this.modalService.errorModal(errorText);
              //   }
              // );
            }
          }
        );
      } else {
        reserveTransaction();
      }
    // });
  }

  /**
   * Displays the restart modal
   */
  openRestartModal(): void {
    if (this.getTimer === '00:00') {
      this.transactionExpiredModal();
    } else {
      this.modalService.restartModal(this.planId, this.interval);
    }
  }

  /**
   * Filter the transaction and put the seats into their respective array
   * @param customerPlan to loop the transaction seats
   */
  filterPlanSeats(customerPlan: CustomerPlan): void {
    this.keptSeats = [];
    this.selectedSeats = [];
    this.discardedSeats = [];
    const pushSeats = (transactionSeatsArray, type: 'normal' | 'related') => {
      transactionSeatsArray.forEach(element => {
          if ((element.seat.includes('PREMIUM') && type === 'related') && (this.configService.client === 'kcroyals')) {
              return;
          }
        if (element.action === 'keep') {
          // if action is 'keep' means that it has to be as discarded and selected
          this.keptSeats.push(this.transactionSeat2Seat(element));
        }
        // if action is 'keep' means that it has to be as discarded
        if (element.action === 'remove') {
          this.discardedSeats.push(this.transactionSeat2Seat(element));
        }
        // if action is 'keep' means that it has to be as  selected
        if (element.action === 'add') {
          this.selectedSeats.push(this.transactionSeat2Seat(element));
        }
      });
    };
    pushSeats(customerPlan.transaction.seat_transactions, 'normal');
    if (customerPlan.transaction.related) {
        pushSeats(customerPlan.transaction.related.seat_transactions, 'related');
    }
    this.keptSeats.sort();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.timerObservable.unsubscribe();
  }

  /**
   * Parses the transaction seat into seat object
   * @param transactionSeat to parse
   * @returns Seat object
   */
  transactionSeat2Seat(transactionSeat: SeatTransaction, priceString = null) {
    // splits the transaction seat
    const seat = transactionSeat.seat.split('_')[1].split('-');
    return { id: transactionSeat.seat, seat: seat[2], seat_row: seat[1], section: seat[0], price: transactionSeat.price, priceString };
  }

  getTheme() {
    return this.themeSelector.isDarkTheme;
  }

  /**
   * Check if the browser it's mobile
   * @returns true in case of mobile, othewise false
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }


  showPayInFull() {
    return this.app_config.rules.showPayInFull
  }

  /**
   * Hides payment with credit card when digital wallet is selected or we are paying with payment plan withouth initial payment
   * or when we are paying all with credit
   */
  isBraintreeHidden() {
    // return this.paymentOptions.card_wallet === 'wallet'
    //   || (this.paymentOptions.option === 'plan')
    //   || this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance;
    return this.paymentOptions.card_wallet === 'wallet' && this.paymentOptions.option !== 'plan'
      || (this.paymentOptions.option === 'plan' && !this.paymentOptions.payment_plan_initial_payment)
      || this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance
      // nationals asked to hide this for payment plan
      || this.smpClient === 'washingtonnationals' && this.paymentOptions.option === 'plan'
      || this.smpClient === 'athletics'
  || this.smpClient === 'minnesotatwins';
  }

  /**
   * hidden in 5 situations:
   *  if don't installments and we have no initial payment and option is plan
   *  if card selected and no installments
   *  if full and credit pays all
   *  if payment plan with initial payment and credit pays all and no installments
   */
  isDigitalWalletHidden() {
    return this.paymentOptions.no_installments && !this.paymentOptions.payment_plan_initial_payment && this.paymentOptions.option === 'plan'
      || this.paymentOptions.card_wallet === 'card' && this.paymentOptions.no_installments
      || this.paymentOptions.option === 'full' && this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance
      && this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance && this.paymentOptions.no_installments;
  }

  /**
   * Hides the select card or digital wallet for payment when is plan with no initial payment or credit to apply is superior
   * than total balance
   */
  isPayWithCardOrWalletHidden() {
    return this.paymentOptions.option === 'plan'
      || this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance;
    // return this.paymentOptions.option === 'plan' && !this.paymentOptions.payment_plan_initial_payment
    // || this.paymentOptions.credit_to_apply >= this.paymentOptions.total_balance;
  }

  /**
   * if all the payment options all hidden hide the div, if not a little blank frame is show
   */
  isPaymentContainerHidden() {
    return this.isDigitalWalletHidden() && this.isBraintreeHidden();
  }

  onChangeAllWithCredit(allWithCredit: boolean) {
    if (allWithCredit) {
      // this.paymentOptions.credit_to_apply = this.tdcTransaction.credit_available;
      // this.paymentOptions.credit_to_apply = this.paymentOptions.total_balance;
        const ask = (this.tdcTransaction.credit_available > this.paymentOptions.total_balance);
        this.paymentOptions.credit_to_apply = ask ? this.paymentOptions.total_balance : this.tdcTransaction.credit_available;
        this.paymentOptions.consume_credits = true;
    } else {
      this.paymentOptions.credit_to_apply = 0;
      this.paymentOptions.consume_credits = false;
    }
  }

  /**
   * when we select a payment plan we check if has any installment with due now for setting the total_balance to pay
   */
  onSelectPaymentPlan(paymentPlan: any) {
    let firstPaymentAmount = 0;
    this.paymentOptions.payment_plan_initial_payment = false;
    this.paymentOptions.total_balance = this.tdcTransaction.balance;
    // tslint:disable-next-line:forin
    for (const index in paymentPlan.installments) {
      const installment = paymentPlan.installments[index];
      if (installment.due_now) {
        firstPaymentAmount = firstPaymentAmount + installment.amount;
        this.paymentOptions.payment_plan_initial_payment = true;
      }
      // if (this.smpClient == 'athletics') // athletics don't want to show the option to pay first install
      //   this.paymentOptions.payment_plan_initial_payment = false;
    }
    this.paymentOptions.total_balance = firstPaymentAmount;
  }

  /**
   * when we select payment with full or plan we have to change the balance to pay
   */
  onChangefullPlan(fullPlan: string) {
    // if full restore payment to balance
    if (fullPlan === 'full') {
        // tslint:disable-next-line:max-line-length
      // this.paymentOptions.total_balance = (this.smpClient !== 'washingtonnationals') ? this.tdcTransaction.balance : this.calculateTotalBalanceNationals();
      this.paymentOptions.total_balance = this.tdcTransaction.balance;
      // this.paymentOptions.reservation = false;
      // else reexecute as if payment plan selected
    } else if (this.paymentOptions.payment_plan) {
      this.onSelectPaymentPlan(this.paymentOptions.payment_plan);
    }
    // if plan and discarded seats show modal
    // if (this.configService.client !== 'kcroyals' &&
    //     this.configService.client !== 'washingtonnationals' &&
    //     this.configService.client !== 'chicagocubs') {
    //     if (fullPlan === 'plan') {
    //         if (this.discardedSeats.length > 0) {
    //             this.paymentOptions.reservation = true;
    //             this.messageService.error('Error', `Due to temporary restrictions, if you would like to set up a payment plan and
    //       you have seats to return, the system will place a reservation for your new seats and return your old seats. A refund
    //       in form of credit will be available in your account. The club will reach to you shortly to complete the transaction.`);
    //         }
    //     }
    // }
    console.log(this.paymentOptions);
  }

  /*
  * Calculate Total balance for Nationals
  */
  calculateTotalBalanceNationals(): number {
      return this.totalPriceAddSeatsSelected - this.totalPriceOnlyDiscarded;
  }


  cancelTransactionObservable(): Observable<any> {
    // gets the transacion by plan id
    const transaction = this.userService.getTransactionByPlanId(this.planId);
    // subscribe to cancelTransaction
    return this.userService.cancelTransaction(transaction.id);
  }

  /**
   * @description Devolver el precio formateado, si algun club quiere otro valor se setea aqui.
   * @returns
   */
  returnCorrectPrice(): string | 0 {
    let balance = this.tdcTransaction.balance;
    if (this.smpClient === 'miamimarlins') {
      balance = this.totalPriceSelected;
    }
    return this.formatPrice(balance)
  }

  openAddDigitalWalletModal() {
    // the proper way of doing this would be subscribed to the addDigitalWalletModal output or having a signal to update
    // the dirty way is passing the tdc transaction and updating the field read by the ng for in the template
    this.modalService.addDigitalWalletModal(this.tdcTransaction);
  }

  // only shown on ahtletics initially
  showAddCreditCardButton() {
    return this.smpClient === 'athletics';
  }
}
