import { TicketManagementPortalConfiguration } from './ticket-management-portal-configuration.model';
import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<TicketManagementPortalConfiguration>('TicketManagementPortalConfiguration');
export const ApplicationConfiguration: TicketManagementPortalConfiguration = {
    flow: 'relocation',
    endDate: null,
    assets: {
        logoUrl: '../../assets/img/logo.png',
        bgUrl: ''
    },
    purchaseLink: null,
    guide: {
        mobileSteps: 0,
        desktopSteps: 0
    },
    topbar: {
        includeInfoIcon: true,
        includeSwitchTheme: false
    },
    colors: {
        main: '',
        accent: '',
        glow: '',
        mapElement: {
            available: '',
            selected: '',
            pending: ''
        }
    },
    dvmConfiguration: {
        venue_id: '',
        map_id: '',
        version: 'development'
    },
    generalAdmissionAreas: [],
    rules: {
        allowUpgrades: false,
        limitCheckoutBalance: false,
        limitCheckoutSeats: false,
        preventIsolatedSeats: true,
        selectSeatsInGroupsOf: 1,
        allowPaymentMethods: false,
        redirectUrl: '',
        areaNeighborsNavigation: false,
        transactionTimerLimit: 5,
        defaultPaymentMethod: 'full',
        showPayInFull: true,
        hasParking: false,
        parkingMaxSlots: 0
    },
    modals:{
      warmWelcome: false
    },
    footer: {
        copyrightText: '2024 3D Digital Venue by Mobile Media Content (MMC).'
    },
    text: {
        title: '',
        subtitle: '',
        salesRep: {
            title: ''
        },
        landing: {
            plansTitle: '',
            plansNoSeatsTitle: ''
        },
        planElement: {
            processAvailable: '',
            processStart: '',
            seatsTitle: '',
            noSeatsText: ''
        },
        subInterface: {
            upgradePlanText: '',
            noUpgradePlanText: '',
            noUpgradeAddons: '',
        },
        checkout: {
            preLegalText: '',
            legalText: '',
            newSeatsText: '',
            totalBalance: '',
            previousSeatsText: '',
            alreadyPaid: '',
            selectDigitalWalletText: ''
        },
        bottom: {
            tooltip: ''
        },
        summary: {
            headerText: '',
            legalText: ''
        },
        modals: {
            checkoutModal: '',
            congratulationsModal: '',
            checkout2ConfirmationModal: '',
            welcomeModal: '',
            welcomeModalTitle: ''
        },
        processDisabledText: {
            title: '',
            text: ''
        },
        legendMapText: {
            ownedSeats: ''
        }
    }
};
