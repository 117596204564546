import { ChoiceItem } from './choice-configuration.model';
import { InjectionToken } from '@angular/core';

export const ChoiceConfig = new InjectionToken<ChoiceItem []>('Choice Configuration');

export const ChoiceConfiguration: ChoiceItem [] = [
  {
    planId: 8,
    name: '$600',
    image: 'choice_600.png',
    priceScaleId: 3567,
    priceScaleName: 'Choice600',
    sections: [
      'S_101',
      'S_102',
      'S_126',
      'S_127',
      'S_201',
      'S_202',
      'S_203',
      'S_204',
      'S_205',
      'S_206',
      'S_207',
      'S_208',
      'S_209',
      'S_210',
      'S_211',
      'S_212',
      'S_213',
      'S_214',
      'S_215',
      'S_216',
      'S_217',
      'S_218',
      'S_219',
      'S_220',
      'S_221',
      'S_222',
      'S_223',
      'S_224',
      'S_225',
      'S_226',
      'S_227',
      'S_228',
      'S_229',
      'S_230',
      'S_231',
      'S_232',
      'S_233',
      'S_234',
      'S_237',
      'S_238',
      'S_239',
      'S_240',
      'S_301',
      'S_302',
      'S_303',
      'S_304',
      'S_305',
      'S_306',
      'S_307',
      'S_308',
      'S_309',
      'S_310',
      'S_311',
      'S_312',
      'S_313',
      'S_314',
      'S_315',
      'S_316',
      'S_317',
      'S_318',
      'S_319',
      'S_320',
      'S_321',
      'S_322',
      'S_323',
      'S_325',
      'S_326',
      'S_327',
      'S_328',
      'S_329',
      'S_330',
      'S_331',
      'S_332',
      'S_333',
      'S_334'
    ]
  },
  {
    planId: 9,
    name: '$900',
    image: 'choice_900.png',
    priceScaleId: 3567,
    priceScaleName: 'Choice900',
    sections: [
      'S_101',
      'S_102',
      'S_103',
      'S_104',
      'S_105',
      'S_106',
      'S_107',
      'S_108',
      'S_120',
      'S_121',
      'S_122',
      'S_123',
      'S_124',
      'S_125',
      'S_126',
      'S_127',
      'S_128',
      'S_129',
      'S_130',
      'S_131',
      'S_132',
      'S_133',
      'S_134',
      'S_135',
      'S_136',
      'S_137',
      'S_138',
      'S_139',
      'S_140',
      'S_201',
      'S_202',
      'S_203',
      'S_204',
      'S_205',
      'S_206',
      'S_207',
      'S_208',
      'S_209',
      'S_210',
      'S_211',
      'S_212',
      'S_213',
      'S_214',
      'S_215',
      'S_216',
      'S_217',
      'S_218',
      'S_219',
      'S_220',
      'S_221',
      'S_222',
      'S_223',
      'S_224',
      'S_225',
      'S_226',
      'S_227',
      'S_228',
      'S_229',
      'S_230',
      'S_231',
      'S_232',
      'S_233',
      'S_234',
      'S_237',
      'S_238',
      'S_239',
      'S_240',
      'S_301',
      'S_302',
      'S_303',
      'S_304',
      'S_305',
      'S_306',
      'S_307',
      'S_308',
      'S_309',
      'S_310',
      'S_311',
      'S_312',
      'S_313',
      'S_314',
      'S_315',
      'S_316',
      'S_317',
      'S_318',
      'S_319',
      'S_320',
      'S_321',
      'S_322',
      'S_323',
      'S_325',
      'S_326',
      'S_327',
      'S_328',
      'S_329',
      'S_330',
      'S_331',
      'S_332',
      'S_333',
      'S_334'
    ]
  },
  {
    planId: 10,
    name: '$1500',
    image: 'choice_1500.png',
    priceScaleId: 3567,
    priceScaleName: 'Choice1500',
    sections: [
      'S_101',
      'S_102',
      'S_103',
      'S_104',
      'S_105',
      'S_106',
      'S_107',
      'S_108',
      'S_109',
      'S_110',
      'S_111',
      'S_112',
      'S_113',
      'S_114',
      'S_115',
      'S_116',
      'S_117',
      'S_118',
      'S_119',
      'S_120',
      'S_121',
      'S_122',
      'S_123',
      'S_124',
      'S_125',
      'S_126',
      'S_127',
      'S_128',
      'S_129',
      'S_130',
      'S_131',
      'S_132',
      'S_133',
      'S_134',
      'S_135',
      'S_136',
      'S_137',
      'S_138',
      'S_139',
      'S_140',
      'S_S',
      'S_T',
      'S_U',
      'S_V',
      'S_201',
      'S_202',
      'S_203',
      'S_204',
      'S_205',
      'S_206',
      'S_207',
      'S_208',
      'S_209',
      'S_210',
      'S_211',
      'S_212',
      'S_213',
      'S_214',
      'S_215',
      'S_216',
      'S_217',
      'S_218',
      'S_219',
      'S_220',
      'S_221',
      'S_222',
      'S_223',
      'S_224',
      'S_225',
      'S_226',
      'S_227',
      'S_228',
      'S_229',
      'S_230',
      'S_231',
      'S_232',
      'S_233',
      'S_234',
      'S_237',
      'S_238',
      'S_239',
      'S_240',
      'S_301',
      'S_302',
      'S_303',
      'S_304',
      'S_305',
      'S_306',
      'S_307',
      'S_308',
      'S_309',
      'S_310',
      'S_311',
      'S_312',
      'S_313',
      'S_314',
      'S_315',
      'S_316',
      'S_317',
      'S_318',
      'S_319',
      'S_320',
      'S_321',
      'S_322',
      'S_323',
      'S_325',
      'S_326',
      'S_327',
      'S_328',
      'S_329',
      'S_330',
      'S_331',
      'S_332',
      'S_333',
      'S_334'
    ]
  },
  {
    planId: 11,
    name: '$3000',
    image: 'choice_3000.png',
    priceScaleId: 3567,
    priceScaleName: 'Choice3000',
    sections: [
      'S_1',
      'S_16',
      'S_17',
      'S_101',
      'S_102',
      'S_103',
      'S_104',
      'S_105',
      'S_106',
      'S_107',
      'S_108',
      'S_109',
      'S_110',
      'S_111',
      'S_112',
      'S_113',
      'S_114',
      'S_115',
      'S_116',
      'S_117',
      'S_118',
      'S_119',
      'S_120',
      'S_121',
      'S_122',
      'S_123',
      'S_124',
      'S_125',
      'S_126',
      'S_127',
      'S_128',
      'S_129',
      'S_130',
      'S_131',
      'S_132',
      'S_133',
      'S_134',
      'S_135',
      'S_136',
      'S_137',
      'S_138',
      'S_139',
      'S_140',
      'S_A',
      'S_B',
      'S_C',
      'S_D',
      'S_E',
      'S_F',
      'S_G',
      'S_H',
      'S_J',
      'S_K',
      'S_L',
      'S_M',
      'S_N',
      'S_P',
      'S_Q',
      'S_R',
      'S_S',
      'S_T',
      'S_U',
      'S_V',
      'S_DELTASTE',
      'S_201',
      'S_202',
      'S_203',
      'S_204',
      'S_205',
      'S_206',
      'S_207',
      'S_208',
      'S_209',
      'S_210',
      'S_211',
      'S_212',
      'S_213',
      'S_214',
      'S_215',
      'S_216',
      'S_217',
      'S_218',
      'S_219',
      'S_220',
      'S_221',
      'S_222',
      'S_223',
      'S_224',
      'S_225',
      'S_226',
      'S_227',
      'S_228',
      'S_229',
      'S_230',
      'S_231',
      'S_232',
      'S_233',
      'S_234',
      'S_237',
      'S_238',
      'S_239',
      'S_240',
      'S_301',
      'S_302',
      'S_303',
      'S_304',
      'S_305',
      'S_306',
      'S_307',
      'S_308',
      'S_309',
      'S_310',
      'S_311',
      'S_312',
      'S_313',
      'S_314',
      'S_315',
      'S_316',
      'S_317',
      'S_318',
      'S_319',
      'S_320',
      'S_321',
      'S_322',
      'S_323',
      'S_325',
      'S_326',
      'S_327',
      'S_328',
      'S_329',
      'S_330',
      'S_331',
      'S_332',
      'S_333',
      'S_334'
    ]
  },
  {
    planId: 12,
    name: '$7500',
    image: 'choice_7500.png',
    priceScaleId: 3567,
    priceScaleName: 'Choice7500',
    sections: [
      'S_1',
      'S_2',
      'S_3',
      'S_4',
      'S_5',
      'S_6',
      'S_7',
      'S_8',
      'S_9',
      'S_10',
      'S_11',
      'S_12',
      'S_13',
      'S_14',
      'S_15',
      'S_16',
      'S_17',
      'S_101',
      'S_102',
      'S_103',
      'S_104',
      'S_105',
      'S_106',
      'S_107',
      'S_108',
      'S_109',
      'S_110',
      'S_111',
      'S_112',
      'S_113',
      'S_114',
      'S_115',
      'S_116',
      'S_117',
      'S_118',
      'S_119',
      'S_120',
      'S_121',
      'S_122',
      'S_123',
      'S_124',
      'S_125',
      'S_126',
      'S_127',
      'S_128',
      'S_129',
      'S_130',
      'S_131',
      'S_132',
      'S_133',
      'S_134',
      'S_135',
      'S_136',
      'S_137',
      'S_138',
      'S_139',
      'S_140',
      'S_A',
      'S_B',
      'S_C',
      'S_D',
      'S_E',
      'S_F',
      'S_G',
      'S_H',
      'S_J',
      'S_K',
      'S_L',
      'S_M',
      'S_N',
      'S_P',
      'S_Q',
      'S_R',
      'S_S',
      'S_T',
      'S_U',
      'S_V',
      'S_DELTASTE',
      'S_201',
      'S_202',
      'S_203',
      'S_204',
      'S_205',
      'S_206',
      'S_207',
      'S_208',
      'S_209',
      'S_210',
      'S_211',
      'S_212',
      'S_213',
      'S_214',
      'S_215',
      'S_216',
      'S_217',
      'S_218',
      'S_219',
      'S_220',
      'S_221',
      'S_222',
      'S_223',
      'S_224',
      'S_225',
      'S_226',
      'S_227',
      'S_228',
      'S_229',
      'S_230',
      'S_231',
      'S_232',
      'S_233',
      'S_234',
      'S_237',
      'S_238',
      'S_239',
      'S_240',
      'S_301',
      'S_302',
      'S_303',
      'S_304',
      'S_305',
      'S_306',
      'S_307',
      'S_308',
      'S_309',
      'S_310',
      'S_311',
      'S_312',
      'S_313',
      'S_314',
      'S_315',
      'S_316',
      'S_317',
      'S_318',
      'S_319',
      'S_320',
      'S_321',
      'S_322',
      'S_323',
      'S_325',
      'S_326',
      'S_327',
      'S_328',
      'S_329',
      'S_330',
      'S_331',
      'S_332',
      'S_333',
      'S_334'
    ]
  },
  {
    planId: 8,
    name: 'Compare',
    image: 'choice_compare.png',
    priceScaleId: null,
    priceScaleName: null,
    sections: []
  },
];
