import { Injectable } from '@angular/core';
import { ModalComponent } from './index';
import { ModalData } from '../models/index';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class MessagingService {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  private prepareObject(object) {
    for (const i in object) {
      if (object[i] === undefined) {
        delete object[i];
      }
    }
  }

  error(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
    const initialState: ModalData = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += ' error-modal';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(ModalComponent, {class: modalParentClass, initialState});
  }

  info(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?, optionalCta?, optionalCtaTitle?, optionalCtaFunction?) {
    const initialState: ModalData = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
      optionalCta,
      optionalCtaTitle,
      optionalCtaFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += '';
    modalParentClass += ' modal-dialog-centered';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(ModalComponent, {class: modalParentClass, initialState});
  }

  warning(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
    const initialState: ModalData = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += ' warning-modal';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(ModalComponent, {class: modalParentClass, initialState});
  }

}
